import React from "react";
export default  {
    UHF:{
        h_new:'h_new',
        menuIconColor:'white',
        headerLogo:"logo (1).svg",
        headerIconCss:"h_white_logo",
        MemberAppsBackGround:"web_orange_tag",
        AiAssistantIconBack:"web_yellow_tag",
        headerMemberApp:"web_orange_tagh",
        AiAssistantIconAppHeader:"web_yellow_tagh",
        yellowPopupColorButton:'#eb5757',
        mainWrapperMarginTop:"20%",
        contactinfo_Value:"contactinfo_Value",
        backgroundColorOfFooter:'#424951',
        footerTextColor:"#e5e7ea",
        linkedin:"linkedin.svg",
        facebook:"facebook.png",
        instagram:"instagram.png",
        youtube:"youtube.svg",
        notificationCardBackColor:"cardwallet_back_notification",
        DocumentCardBackColor:"documents_infomiddiv",
        documentBackColor:"documentscardnew_welcome_div",
        ContactCardBgColor:"contact_infomiddiv",
        myNoticationLabel:"notification_label_backside",
        DocumentSHeader:"myneeds_header_text",
        captiontextdiv:"captiontextdiv",
        cardwallet_label_backside:"cardwallet_label_backside",
        cardwallet_back_text:"cardwallet_back_text",
        uhf:true,
        documentsicon:"documents-icon-active.svg",
        backpage:"Back_page",
        loginform_containernew:"loginform_containernew",
        pviewer:"p-viewer",
        forgetpasswordtextmargin:"forgot_text-margin",
        login_button:"login_button",
        loginmobile_forgot_text:"loginmobile_forgot_text",
        login_plese_text:"login_plese_text",
        checkregister_blue_text_container:"checkregister_blue_text_container",
        checkregister_button:"checkregister_button",
        rege_goto:"rege_goto",
        reset_note :"reset_note",
        membershipcardicon:"membership-card-icon-active.svg",
        programinfoiconactive:"program-info-icon-active.svg",
        myNeedsIcons:"my-needs-icon-active.svg",
        faqIcon:"FAQs icon (active).svg",
    },
    NetWell:{
        h_new:'h_new_netWell',
        menuIconColor:'black',
        headerLogo:"netwell-logo.png",
        headerIconCss:"h_white_logo_Netwell",
        MemberAppsBackGround:"web_orange_tag_netwell",
        AiAssistantIconBack:"web_yellow_tag_netwell",
        headerMemberApp:"web_orange_tagh_netwell",
        AiAssistantIconAppHeader:"web_yellow_tagh_netwell",
        yellowPopupColorButton:"#162242",
        mainWrapperMarginTop:"10%",
        contactinfo_Value:"contactinfo_Value_Netwell",
        backgroundColorOfFooter:'#e3e3e3',
        footerTextColor:"#7e7e7e",
        linkedin:"linkedinBlack.svg",
        facebook:"facebookBlack.svg",
        instagram:"instagramBlack.svg",
        youtube:"twitterIcon.png",
        notificationCardBackColor:"cardwallet_back_notification_Netwell",
        DocumentCardBackColor:"documents_infomiddiv_Netwell",
        documentBackColor:"documentscardnew_welcome_div_netwell",
        ContactCardBgColor:"contact_infomiddiv_netwell",
        myNoticationLabel:"notification_label_backside_netwell",
        DocumentSHeader:"myneeds_header_text_netwell",
        captiontextdiv:"captiontextdivNetwell",
        cardwallet_label_backside:"cardwallet_label_backside_netwell",
        cardwallet_back_text:"cardwallet_back_text_netwell",
        uhf:false,
        documentsicon:"documents-icon-yellow.svg",
        backpage:"Back_page_netwell",
        loginform_containernew:"loginform_containernetwell",
        pviewer:"p-viewer-netwell",
        forgetpasswordtextmargin:"forgot_text-margin_netwell",
        login_button:"login_button_netwell",
        loginmobile_forgot_text:"loginmobile_forgot_text_netwell",
        login_plese_text:"login_plese_text_netwell",
        checkregister_blue_text_container:"checkregister_blue_text_container_netwell",
        checkregister_button:"checkregister_button_netwell",
        rege_goto:"rege_goto_netwell",
        reset_note :"reset_note_netwell",
        membershipcardicon:"membericon.png",
        programinfoiconactive:"program-info-icon-active-yellow.svg",
        myNeedsIcons:"my-needs-netwell.svg",
        faqIcon:"faq yellow.svg",
    }
}