import React, { Component } from 'react'
import Modal1 from 'react-awesome-modal'
import { Modal, Toast } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import customStyle from '../../../programInfo/CSS/stylesheet_UHS'
import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/core/styles'
import '../../../../../components/authentication/style.scss'
import { TextField } from '@material-ui/core'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import '../../../ReimbursementRequest/Reimbursement.css'
const NextButton = withStyles(customStyle.viewBtn)(Button)

class ReimbursementModal2 extends Component {
  constructor(props) {
    super(props)
    let emailToReset = sessionStorage.getItem('emailToReset')

    this.state = {
      tempNotAvailableModal: true,
     
    }
  }
 
  render() {
    return (
      <Modal1 show={this.showModal} onHide={this.handleClose}  backdrop="static">
        <div style={{ width: '40vw', height: '30vw' }}>
          <ModalHeader>Proof of Payment for services </ModalHeader>
          <div className="tempModalTxt">
            <div className="uploadWrapContainer">
              <div className="drop-it-hot" id="drop-area" onDrop={this.handleDrop}>
                <form className="choose-files">
                  <svg
                    fill="currentColor"
                    height="62"
                    viewBox="0 0 24 24"
                    width="62"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ color: '#BFBFBF' }}
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
                  </svg>
                  <div className="button-wrapper">
                    <label className="label" for="fileElem" style={{ width: '28%', textAlign: 'center' }}>
                      Drag and drop file or
                    </label>
                    <div style={{ position: 'relative', height: '40px', marginBottom: '15px' }}>
                      <input  style={{ display: 'none' }} id="raised-button-file" accept=".jpg,.png,.doc,.pdf,.zip" onChange={this.handleChangeFile} onClick={e => (e.target.value = null)} multiple type="file" />
                      <label htmlFor="raised-button-file">
                        <Button variant="raised" component="span" className="r-btn">
                          Browse
                        </Button>
                      </label>
                      <div style={{textAlign:"center"}}>Maximum file size: 25MB<br></br> Supported file types: JPG,PNG,DOC,PDF,ZIP</div>
                    </div>

                    <div style={{ height: '50px' }}></div>
                  </div>
                </form>
                <div id="gallery"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="mqalert_button_modal">
          <Button className="mqalertbutton_cancel" onClick={this.handleClose}>CANCEL</Button>
          <Button className="mqalertbutton">SAVE</Button>
        </div>
      </Modal1>
    )
  }
}
export default ReimbursementModal2
