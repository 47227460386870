import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { Component } from 'react'
import { getCardDetails, getCardEnableData, getDocuments, getsharingguidlineslink, getWelcomeBooklet } from '../ApiCall'
import CommonLoader from '../CommonLoader'
import ChatIcon from '../WebScreen/ChatBox/ChatIcon'
import MobCopyright from './MobCopyright'
import MobileFooter from './MobileFooter'
import styleJson from '../styleJSON'

window.mobileAndTabletCheck = function() {
  let check = false
  ;(function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

export default class DocumentsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      enable: null,
      loader: false,
      providerLink: '',
      documentlist: null,
      netWellUser: process.env.REACT_APP_NETWELLUSER == "true"? true :false,
      styles: process.env.REACT_APP_NETWELLUSER == "true"? styleJson.NetWell :styleJson.UHF,
    }
  }



  componentDidMount() {
    this.setState({ loader: true })
    getDocuments().then(response=>{
      this.setState({documentlist: response, loader: false})
    })
    this.getCardEnable()
    getCardDetails().then(res => {
      if (res.data.response) {
        let documentCardData = res.data.response.find(obj => obj.cardtitle === 'Documents')
        this.setState({
          enable: documentCardData.enable,
          loader: false
        })
      }
    })
  }

  getCardEnable = () => {
    let client_id = localStorage.getItem('CLIENT_ID')

    this.setState({ loader: true })
    getCardEnableData(client_id, 'Documents').then(res => {
      console.log('getCardEnableData=====', res.data.response.enable)
      this.setState({ loader: false })
      if (res.data.response.enable == 'false' || res.data.response.enable == false) {
        window.location.href = '/'
      }
    })
  }

  goBack = () => {
    this.props.history.goBack()
  }




  render() {
    return (
      <div>
        {this.state.loader ? <CommonLoader /> : null}
        <div class={this.state.netWellUser?"digitalcard_headerNetWell":"digitalcard_header"}>
          <ArrowBackIcon style={{ width: '24px', height: '24px', color: this.state.netWellUser?'#000000':'#ffffff' }} onClick={() => this.goBack()} />
          <div class={this.state.netWellUser?"digitalcard_headerdivNetWell":"digitalcard_headerdiv"}>Documents</div>
          {/* <NotificationsIcon style={{width:'24px',height:'24px',color:'rgba(255, 255, 255, 0.74)'}}/> */}
        </div>

      {this.Document()}

            {/* <ChatIcon showAIChatIcon={true} />
          <ChatIcon />
          <MobCopyright  style={{display: 'relative', marginBottom: 60}} />
        <div class="fixed-bottom">
          <MobileFooter />
        </div> */}
          <div>
              {this.state.loader ?null: <div>
                <div style={{ bottom:window.mobileAndTabletCheck()? '9vh': "2vh ", position: 'relative' }}>
                  <ChatIcon showAIChatIcon={true} />
                  <ChatIcon />
                </div>
                <div style={{  bottom:window.mobileAndTabletCheck()? '9vh': " ", position: 'relative' }}>
                  <MobCopyright />
                </div>
              </div>}
          </div>
          <div class="fixed-bottom">
            <MobileFooter name="Documents"/>
          </div>
      </div>
    )
  }
  Document=() => {
    if(this.state.documentlist && this.state.documentlist.length > 0)
  return(<div className="">
    <div className="doc_infomiddiv text-left" style={{overflowY:"auto",height:"70vh"}}>
    {this.state.documentlist.map(data=>
      <div className="doc_welcome_text" onClick={() => window.open(data.document)}>
          {data.description}
      </div>)}
    </div>
    <div className="container contactcard_footer"></div>
</div>)
  else
  return   <div clasName="data_not_found" style={{height:'80vh',display:'flex',alignItems:'center',justifyContent:'space-evenly'}}>
  <div className="noneeds_msg_display" style={{ padding: '35px'}}>
  We are currently updating this feature and will be up shortly. Stay tuned!</div>
</div>
  }
}
  