import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { createMuiTheme, lighten, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles';
import shareNeeds from '../../../../assets/images/shareNeeds.svg'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import '../../MyNeeds.css'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { replace } from 'connected-react-router'
import Reimbursement from '../Reimbursement.css'

function createData(no, date_of_service, provider, charged, paid, status) {
  return { no, date_of_service, provider, charged, paid, status }
}

const StyledTable = withStyles((theme) => ({
  root: {
    width: "max-content"
  }
}))(Table);


window.mobileAndTabletCheck = function() {
  let check = false
  ;(function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}
const theme = createMuiTheme({
  MuiTableCell: {
    paddingLeft: '30px'
  }
})

const rows = [
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW')
                      ]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const headCells = [
          
  // { id: 'arrow', numeric: true, disablePadding: false, label: '' },
  { id: 'id', numeric: true, disablePadding: false, label: 'Reference #' },
  { id: 'serviceDate', numeric: true, disablePadding: false, label: 'Date Of Service' },
    { id: 'memberName', numeric: false, disablePadding: false, label: 'Member' },
  ,
  { id: 'providerName', numeric: false, disablePadding: false, label: 'Provider' },
  { id: 'chargedAmount', numeric: true, disablePadding: false, label: 'Charged Amount' },
  { id: 'paidAmount', numeric: true, disablePadding: false, label: ' Paid Amount' },
  { id: 'createdDate', numeric: true, disablePadding: false, label: 'Submitted /Last Edit Date' },
  { id: 'status', numeric: true, disablePadding: false, label: ' Status' },
  { id: '', numeric: true, disablePadding: false, label: 'Action' }
]

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow className="tableHeadRow"  >
        {/* <TableCell padding="checkbox">
         <Checkbox
           indeterminate={numSelected > 0 && numSelected < rowCount}
           checked={rowCount > 0 && numSelected === rowCount}
           onChange={onSelectAllClick}
           inputProps={{ 'aria-label': 'select all desserts' }}
         />
       </TableCell> */}
        {headCells.map(headCell => (
          <>
            {headCell.id == 'service_date' ? (
              <TableCell
                key={headCell.id}
                                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                className="sortheadercellColor tableHeader"
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  className="headercellColor tableHeader"
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                key={headCell.id}
                 padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                className="headercellColor tableHeader"
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  className="headercellColor tableHeader"
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )}
          </>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%'
  }
}))





const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

export default function ReimbursementData(props) {
  const classes = useStyles()
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('service_date')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { tableData } = props
  useEffect(() => {})

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = event => {
    setDense(event.target.checked)
  }

  const isSelected = name => selected.indexOf(name) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <Paper className={'tableContainer ' + classes.paper} style = {{padding: "0px 15px",}}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        {/* <div className="tablefixheight"> */}
        <TableContainer style={{height: window.mobileAndTabletCheck()?"460px":"300px"}} >
          <StyledTable
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
             stickyHeader
            
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />

            <TableBody>
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((tableData, index) => {
                  const isItemSelected = isSelected(tableData.name)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return <Row update={props.update} open={props.open} key={tableData.name} row={tableData} />
                })}
              {/* {emptyRows > 0 && (
               <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                 <TableCell colSpan={6} />
               </TableRow>
             )} */}
            </TableBody>
          </StyledTable>
        </TableContainer>
       

        <TablePagination
          style={{ position: 'relative' }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

function Row(props) {
  const { row } = props
  const [open, setOpen] = React.useState(props.open)
  const classes = useRowStyles()

  const [modalShow, setModalShow] = React.useState(false)

  const getDate = date => {
    const isDateValid = moment(date)['_isValid']
    if (isDateValid) {
      return moment(date).format('MMMM D, YYYY')
    }
    return date
  }

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const floatconversion = x => {
    return x.toFixed(2)
  }

    const combineDate = (x, y) => {
    if (x == y) {
      return x
    }

    const z = x + '-' + y
    return z
  }

  const convertToString = x => {
    console.log('Type Of X')
        console.log(x)
      }

                
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <TableRow className={'rowTable ' + classes.root}>
        
          <TableCell component="th" scope="row" className="rowcellcolor rowTableCell">
            {row.id}
          </TableCell>
          <TableCell align="left" className="sortrowcellcolor rowTableCell">
            {row.serviceDate? getDate(row.serviceDate): "NA"}
          </TableCell>
          <TableCell component="th" scope="row" className="rowcellcolor rowTableCell">
            {row.memberName? row.memberName: "NA"}
          </TableCell>
         
          <TableCell align="left" className="rowcellcolor rowTableCell">
            {row.providerName ? row.providerName : "NA"}
          </TableCell>
          <TableCell align="left" className="rowcellcolor rowTableCell">
            {row.chargedAmount? "$" + row.chargedAmount :"NA"}
          </TableCell>
          <TableCell align="left" className="rowcellcolor rowTableCell">
            {row.paidAmount? "$" + row.paidAmount : "NA"}
          </TableCell>
          <TableCell align="left" className="rowcellcolor rowTableCell">
            {row.createdDate? getDate(row.createdDate):"NA"}
          </TableCell>
         
          <TableCell align="left" className="rowTableCell" style={{width:175}}>
            {row.status !== "PENDING" ? (
              <span className={'web_status_button Audit'}>{row.status}</span>
            ) : (
              <span className={'web_status_button Logged'}>{row.status.replace('PENDING', 'NOT YET SUBMITTED')}</span>
            )}
          </TableCell>
          <TableCell align="left" className="rowTableCell">
          <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                        <React.Fragment>
                                        <IconButton {...bindTrigger(popupState)}>
                                            <MoreVertIcon/>
                                        </IconButton>
                                        <Menu {...bindMenu(popupState)} onClick={popupState.close}
                                                getContentAnchorEl={null}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center'
                                                }}
                                                transformOrigin={{
                                                    vertical: -10,
                                                    horizontal: 30
                                                }}>
                                            <MenuItem onClick={() => props.update(row.memberId, row.id)}>Update</MenuItem>
                                        </Menu>
                                        </React.Fragment>
                                    )}
                            </PopupState>
          </TableCell>
        </TableRow>

        {/* The Following table row is used to for the information of the dropdown rows that explain the EOS */}
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" component="tr" unmountOnExit style={{ backgroundColor: '#f6f6f6' }}>
              <Box>
                {/* <Table className="collapseTableContainer" size="small" aria-label="purchases">
               <TableHead> */}
                
                {/* </TableHead> */}

              

                  {/* <TableBody colSpan={12}>
                   <TableRow style={{backgroundColor:'#EEEEEE'}}>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds">
                    <div style={{ width:27, paddingLeft: 6}}>
                      <img src={shareNeeds} width={50}/>
                    </div>
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds" colSpan={2}>
                      <p style={{marginTop:16, fontWeight:"500", color: "#454d58"}}>
                      Shared by UHF Members <b style={{  fontWeight:"bold", color: "#00000", fontSize:"16px",}}> ${row.eligible_for_sharing} </b> 
                      </p> 
                    </TableCell>
                    
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds" colSpan={2}>
                      <p style={{width:"auto", marginTop:16, fontWeight:"500", color: "#454d58"}}>
                      No. of Contributing Members  <b style={{  fontWeight:"bold", color: "#00000", fontSize:"16px",}}> 12 </b> 
                      
                      </p> 
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds" colSpan={2}>
                    <button className="ButtonBGNeeds">
                      <p style={{marginTop:5}} className="captionNeeds">SEND THANK YOU NOTE</p>
                      </button>
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds">
                      
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds" colSpan={2}>
                      
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds">
                   
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds">
                   
                    </TableCell>
                    <TableCell padding='1px' align="left" className="innerTh shareNeeds">
                   
                    </TableCell>
                    
                    </TableRow>
                  </TableBody> */}


         
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} data={row} />
      </ThemeProvider>
    </React.Fragment>
  )
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
})

const myrowsjsondata = [
  {
    member_id: '600016',
    bill_key: '20116',
    first_name: 'CHARLES',
    last_name: 'CASH',
    date_paid: 'None',
    date_received: '2020-04-20',
    status: 'IN REVIEW',
    charged_amount: '0.00',
    paid_amount: '0.00',
    paid_provider_name: 'aa'
  },
  {
    member_id: '600017',
    bill_key: '2046',
    first_name: 'CHARLES',
    last_name: 'CASH2',
    date_paid: '2020-05-20',
    date_received: '2020-04-21',
    status: 'FINAL',
    charged_amount: '100.00',
    paid_amount: '10.00',
    paid_provider_name: 'bb'
  },
  {
    member_id: '600018',
    bill_key: '20118',
    first_name: 'CHARLES',
    last_name: 'CASH',
    date_paid: 'None',
    date_received: '2020-04-22',
    status: 'FINAL',
    charged_amount: '200.00',
    paid_amount: '20.00',
    paid_provider_name: 'cc'
  },
  {
    member_id: '600019',
    bill_key: '2053',
    first_name: 'CHARLES',
    last_name: 'CASH2',
    date_paid: '2020-05-20',
    date_received: '2020-04-23',
    status: 'FINAL',
    charged_amount: '300.00',
    paid_amount: '30.00',
    paid_provider_name: 'VITALCARE FP LLC'
  },
  {
    member_id: '600018',
    bill_key: '201337',
    first_name: 'CHARLES',
    last_name: 'CASH',
    date_paid: 'None',
    date_received: '2020-04-24',
    status: 'IN REVIEW',
    charged_amount: '400.00',
    paid_amount: '40.00',
    paid_provider_name: 'VITALCARE FP LLC'
  },
  {
    member_id: '600019',
    bill_key: '201857',
    first_name: 'CHARLES',
    last_name: 'CASH2',
    date_paid: '2020-05-20',
    date_received: '2020-04-25',
    status: 'IN REVIEW',
    charged_amount: '0.00',
    paid_amount: '0.00',
    paid_provider_name: 'VITALCARE FP LLC'
  }
]

function MyVerticallyCenteredModal(props) {
  const { data } = props
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">My Needs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{data.paid_provider_name}</h4>
        <p>{data.member_id}</p>
        <p>{data.charged_amount}</p>
        <p>{data.status}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}
