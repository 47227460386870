import CloseIcon from '@material-ui/icons/Close'
import React, { Component } from 'react'
import styleJson from '../styleJSON'
import { getAgentInfo, gethealthcard, getNetworkName, getSendNeedsToContactAddress, getSourceID } from '../ApiCall'
export default class ContactInformationCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      digitalheathcard: null,
      plainId: null,
      provider: null,
      network: null,
      channel: null,
      contactNumber: null,
      cardId: null,
      showEmpId: false,
      agentemail: null,
      agentname: null,
      agentno: null,
      style: null,
      netWellUser: false,
      styles: process.env.REACT_APP_NETWELLUSER == 'true' ? styleJson.NetWell : styleJson.UHF
    }
  }
  componentDidMount() {
    this.gethealthcarddata()
    this.getSourceIDbyemail()

    this.setState({ style: styleJson.NetWell, netWellUser: true })
  }

  getSourceIDbyemail() {
    // getSourceID().then(res => {
    //   console.log('Member source id issss in contact cardd', res.data.memberIdSource)
    //   this.agentInfoget(res.data.memberIdSource)
    // })
    this.agentInfoget(localStorage.getItem('sourceid'))
  }

  agentInfoget(sourceid) {
    if (sourceid) {
      getAgentInfo(sourceid).then(res => {
        console.log('agent info get========', res)
        console.log('agent info get========', res.data.response)
        if (res.data.response) {
          localStorage.setItem('AgentDetails', JSON.stringify(res.data.response))
          this.setState({
            agentemail: res.data.response.email,
            agentname: res.data.response.name,
            agentno: res.data.response.phone
          })
        } else {
        }
      })
    }
  }

  async gethealthcarddata() {
    gethealthcard().then(res => {
      if (res && res.data && res.data.memberIdCardList != null) {
        this.setState({ digitalheathcard: res.data, plainId: res.data.memberIdCardList[0].planId })
        this.getNetworkData(res.data.memberIdCardList[0].planId)
        this.setContactandCardID(res.data)
      } else {
      }
    })
  }

  setContactandCardID(data) {
    data.memberIdCardList[0].planInfo.map((data, index) => {
      if (data.idcardField == 'contact number') {
        this.setState({ contactNumber: data.fieldValue })
      }
      if (data.idcardField == 'card id') {
        this.setState({ cardId: data.fieldValue })
      }
    })
  }

  getNetworkData(plainid) {
    getNetworkName(plainid).then(res => {
      console.log('netowrok name againt plain id=======', res.data)
      this.setState({
        network: res.data.provider_network === 'HLC' ? 'PHCS' : res.data.provider_network,
        provider: res.data.provider_network
      })
      this.setState({
        loader: false
      })
    })
  }

  render() {
    if (this.state.style)
      return (
        <div class={'contactinfocard text-left'}>
          <div class="myneeds_top_container">
            <img src={require('../Images/LeftDrawer Icon/contact-info-icon-active.svg')} class="myneeds_header_image" />
            <div class={this.state.style.DocumentSHeader}>Contact Information</div>
            {this.props.close ? <CloseIcon class="modal_close_cursor" onClick={this.props.onClick} /> : null}
            {/* < BsThreeDotsVertical   color="#4f4f4f" class="card_threedot" /> */}
          </div>

          {this.props.contactCardData && this.props.contactCardData.enable ? (
            (() => {
              switch ('NETWELL') {
                case 'NETWELL':
                  return (
                    <div class="">
                      <div class={this.state.style.ContactCardBgColor + ' text-left'}>
                        {this.state.contactNumber ? (
                          <>
                            <div class="contactinfo_label">For Pre-notification or Customer Service call: </div>
                            <div class={this.state.style.contactinfo_Value}>(866) NETWELL (638-9355)</div>
                          </>
                        ) : null}

                        <div class="contactinfo_label">Send Medical Sharing Requests to:</div>

                        <div class={this.state.style.contactinfo_Value}>
                          netWell
                          <br />
                          PO Box 14267
                          <br />
                          Reading, PA 19612-4267
                        </div>

                       {localStorage.getItem("SOURCE") == "Optimed" ?
                       <div>
                       <div class="contactinfo_label">Contact Numbers:</div>
                       <div class={this.state.style.contactinfo_Value}>
                         Members: 855-739-6500
                         <br />
                         Providers(For Eligibility): 866-638-9355
                         <br />
                         PreAuthorization: 866-638-9355
                         <br />
                       </div>
                       </div>
                       : <div>
                        <div class="contactinfo_label">netWell Customer Service:</div>
                        <div class={this.state.style.contactinfo_Value}>
                          (866) NETWELL (638-9355)
                          <br />
                          Members: (Option 2)
                          <br />
                          Providers(For Eligibility): (Option 3)
                          <br />
                          PreAuthorization: (Option 4)
                          <br />
                        </div>
                        </div>}
                        <div class="contactinfo_label">Services:</div>
                        <div class={this.state.style.contactinfo_Value}>
                          Telemedicine: 833-794-3863
                          <br />
                          Telecounseling: 833-794-3863
                          <br />
                          Pharmacy: 844-693-5579
                          <br />
                        </div>
                        <div class="contactinfo_label">Discounts:</div>
                        <div class={this.state.style.contactinfo_Value}>
                          Diabetes: 800-210-9222
                          <br />
                          Labs: 800-908-0000
                          <br />
                          Vitamins: 800-824-4491
                          <br />
                          TruHearing®: 855-825-4706
                          <br />
                          Coast to Coast Vision™: 888-868-6199
                          <br />
                          Dental: 888-868-6199
                          <br />
                        </div>
                        {this.state.agentname ? (
                          <>
                            <div class="contactinfo_label">Your Agent Details:</div>
                            <div class={this.state.style.contactinfo_Value}>{this.state.agentname}</div>
                            <div class={this.state.style.contactinfo_Value}>{this.state.agentno}</div>
                            <div class={this.state.style.contactinfo_Value}>{this.state.agentemail}</div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  )
              }
            })()
          ) : (
            <div style={{ padding: '35px' }}>
              <div className="tempModalTxt">We are currently updating this feature and will be up shortly. Stay tuned!</div>
            </div>
          )}

          <div class="container contactcard_footer">
            {/* <div class="row">
                        <div class="col text-left">
                            <FaHeart color="rgba(0, 0, 0, 0.54)" size={16} />
                            <IoMdShare color="rgba(0, 0, 0, 0.54)" size={18} class="ml-4" />
                        </div>
                        <div class="col text-right">
                            <MdKeyboardArrowDown color="rgba(0, 0, 0, 0.54)" size={20} />
                        </div>
                    </div> */}
          </div>
        </div>
      )
    else return null
  }
}
