import React, { Component } from 'react'
import { ButtonGroup, Modal } from 'react-bootstrap'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import customStyle from '../../programInfo/CSS/stylesheet_UHS'
import Card from '@material-ui/core/Card'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import '../../../../components/authentication/style.scss'
import { TextField } from '@material-ui/core'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import '../../ReimbursementRequest/Reimbursement.css';
import CommonLoader from '../../../CommonLoader'
import Dropzone  from 'react-dropzone';
import axios from 'axios';
import { rootCertificates } from 'tls'
import MuiPhoneNumber from "material-ui-phone-number";
import moment from 'moment'
const NextButton = withStyles(customStyle.viewBtn)(Button)

const CssTextField = withStyles(theme => ({
  root: {
    '& .MuiFormLabel-root': {
      '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: '2px solid #533278',
        color:'#533278'
      },
      '&.MuiFilledInput-underline.Mui-focused:after': {
        borderBottom: '2px solid #533278',
        color:'#533278'
      }
    }
  }
}))(TextField)
const CssPhone = withStyles(theme => ({
  root: {
    '& .MuiFormLabel-root': {
      '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: '2px solid #533278',
        color:'#533278'
      },
      '&.MuiFilledInput-underline.Mui-focused:after': {
        borderBottom: '2px solid #533278',
        color:'#533278'
      }
    }
  }
}))(MuiPhoneNumber)

class ReimbursementModal1 extends Component{
    constructor(props) {
        super(props)
        let emailToReset = sessionStorage.getItem('emailToReset')
        this.state = {
          disabled:this.props.disabledBottonPopup,
          blobFile:null,
          name:"",
          loader:false,
          size:null,
          type:null
        }
        
      }
    componentDidMount() {
   
    }
    handleChangeFile=(event) => {
      let file = this.dataURItoBlob(event.target.files[0]);
      this.setState({blobFile:file, name: file.name})
    }
    handleDrop=(event) => {
      this.setState({blobFile:event[0], name: event[0].name, size: event[0].size/1000000, type: event[0].type.split("/")[1].toString()})
      // console.log('file', event[0], event[0].type.split("/")[1])
    }
    onFileUpload = (flag) => {
      if(!flag){
      const formData = new FormData();
      this.setState({loader:true})
      let dateFormat=moment(this.props.DoS).format("MM-DD-YYYY")
      let type;
      if(this.props.checkbox == 4){
        type="RECEIPT"
      }else{
        type="UB04"
      }
      formData.append(
        "file",
        this.state.blobFile,
        dateFormat+"_"+this.props.selectMember+"_"+type+"_"+Date.now()+"."+this.state.blobFile.type.split("/")[1].toString()
      );
      // axios.post(process.env.REACT_APP_BASE_URL_VERSION +"memberportal/payment-proof", formData).then((response) => {
        if(this.props.checkbox == 4){
          this.props.setsuperBill(this.state.blobFile)
          this.setState({name:""})
        }else if(this.props.checkbox == 5){
          this.props.setproof(this.state.blobFile)
          this.setState({name:""})
        }
        this.setState({loader:false})
          this.props.submitModal(this.props.checkbox)
      // });
      }else{
        this.setState({loader:false})
        this.props.submitModal(this.props.checkbox)
      }
    }
    render(){

      let disable = (this.props.checkbox == 1 && this.props.providerNPIName)? false :
      (this.props.checkbox == 0 && this.props.providerName && this.props.providerAddress && this.props.providerNumber.length == 17)? false :
      (this.props.checkbox == 2 && this.props.providerTAXIDName) ? false: 
      (this.props.checkbox == 3 && this.props.CPTCodes) ? false :
      (this.props.checkbox == 6 && this.props.paidAmount && this.props.AmountCharged)? false :
      true;
      // console.log("sasajn",disable, this.props.providerNPIName && this.props.checkbox == 2)
      
        return(
          <div>        
            {this.state.loader ? <CommonLoader /> : null}
          <Modal style={{marginLeft: window.mobileAndTabletCheck() ? 10 : 'none'}} className="col-sm-12" dialogClassName="my-modal" show={this.props.openPopup} onHide={this.handleClose} centered backdrop="static">
          <Modal.Header style={{height:56}}>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <span class={window.mobileAndTabletCheck()?"reimbursement-tilteMob col-sm-6 col-md-12":"reimbursement-tilte col-sm-6 col-md-12"}>{this.props.title}</span>
                <IconButton aria-label="close" style={{ marginTop: '-13px',marginLeft:'-50px',padding:"10px 0px 0px 0px" }} onClick={() =>{   this.setState({name:"", type:null, size:null}); this.props.handleCANCEL(this.props.checkbox)}}>
                  <CloseIcon />
                </IconButton>
              </Grid>
          </Modal.Header>
          <Modal.Body style={{minHeight:(this.props.checkbox == 4 || this.props.checkbox == 5)?350:"auto", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                 {this.props.checkbox == 0?<div style={{marginBottom:8}}>
                <div > 
                  <CssTextField
                    required id="outlined-name"
                    label="Provider Name"
                    variant="filled"
                    autoComplete='off'
                    value={this.props.providerName}
                    onChange={(e)=>{
                      this.props.setProviderName(e.target.value)
                      if(this.props.checkbox == 0 && this.props.providerName!=="" && this.props.providerAddress!=="" && this.props.providerNumber!==""){
                        // console.log("checks", this.props.checkbox , this.props.providerName, this.props.providerAddress , this.props.providerNumber)
                        this.props.setdisabledBottonPopup(false)
                      }else{
                        this.props.setdisabledBottonPopup(true)
                      }
                    }}
                    /></div>  
              <div style={{marginTop:"30px"}}>
                <CssTextField
                    required id="filled-required"
                    label="Provider Address"
                    multiline
                    maxRows={4}
                    variant="filled"
                    autoComplete='off'
                    value={this.props.providerAddress}
                    onChange={(e)=>{
                      this.props.setProviderAddress(e.target.value)
                      if(this.props.checkbox == 0 && this.props.providerName!=="" && this.props.providerAddress!=="" && this.props.providerNumber!==""){
                        // console.log("checks", this.props.checkbox , this.props.providerName, this.props.providerAddress , this.props.providerNumber)
                        this.props.setdisabledBottonPopup(false)
                      }else{
                        this.props.setdisabledBottonPopup(true)
                      }
                    }}
                  />
                </div>
              <div style={{marginTop:"30px"}}>
                {/* <CssTextField
                    required id="filled-required"
                    label="Provider Phone Number"
                    type="number"
                    variant="filled"
                    autoComplete='off'
                    value={this.props.providerNumber}
                    onChange={(e)=>{ 
                      this.props.setproviderNumber(e.target.value)
                      if(this.props.checkbox == 0 && this.props.providerName!=="" && this.props.providerAddress!=="" && this.props.providerNumber!==""){
                        console.log("checks", this.props.checkbox , this.props.providerName, this.props.providerAddress , this.props.providerNumber)
                        this.props.setdisabledBottonPopup(false)
                      }else{
                        this.props.setdisabledBottonPopup(true)
                      }
                    }}
              /> */}
               <CssPhone
                    name="phone"
                    required id="filled-required"
                    label="Provider Phone Number"
                    data-cy="user-phone"
                    variant="filled"
                    defaultCountry={this.props.providerNumber?"us":null}
                    autoComplete='off'
                    value={this.props.providerNumber}
                    onChange={(e)=>{ 
                      this.props.setproviderNumber(e)
                      if(this.props.checkbox == 0 && this.props.providerName!=="" && this.props.providerAddress!=="" && this.props.providerNumber!==""){
                        // console.log("checks", this.props.checkbox , this.props.providerName, this.props.providerAddress , this.props.providerNumber)
                        this.props.setdisabledBottonPopup(false)
                      }else{
                        this.props.setdisabledBottonPopup(true)
                      }
                    }}
                  />
              </div>
              </div>:
              this.props.checkbox == 1?
              <div style={{marginTop:"5px",marginBottom:15}}>
                <CssTextField
                    label="Provider NPI Number"
                    required id="outlined-name"
                    variant="filled"
                    autoComplete='off'
                    value={this.props.providerNPIName}
                    onChange={(e)=>{ 
                      this.props.setproviderNPINumber(e.target.value)
                      if(this.props.checkbox == 1 && this.props.providerNPIName!==""){
                        // console.log("checks", this.props.checkbox , this.props.providerNPIName)
                        this.props.setdisabledBottonPopup(false)
                      }else{
                        this.props.setdisabledBottonPopup(true)
                      }
                    }}
                  />
                </div>:
                this.props.checkbox == 2?
                <div style={{marginTop:"5px",marginBottom:15}}>
                <CssTextField
                autoComplete='off'
                    label="Provider TAX ID Number"
                    required id="outlined-name"
                    variant="filled"
                    value={this.props.providerTAXIDName}
                    onChange={(e)=>{ 
                      this.props.setproviderTAXIDName(e.target.value)
                      if(this.props.checkbox == 2 && this.props.providerTAXIDName!==""){
                        // console.log("checks", this.props.checkbox , this.props.providerNPIName)
                        this.props.setdisabledBottonPopup(false)
                      }else{
                        this.props.setdisabledBottonPopup(true)
                      }
                    }}
                  />
                </div>:
                this.props.checkbox == 3?
                 <div style={{marginTop:"5px",marginBottom:15}}>
                 <CssTextField
                     label="CPT Codes / Diagnosis Codes"
                     required id="outlined-name"
                     variant="filled"
                     autoComplete='off'
                     value={this.props.CPTCodes}
                     onChange={(e)=>{ 
                       this.props.setCPTCodes(e.target.value)
                       if(this.props.checkbox == 3 && this.props.CPTCodes!==""){
                         this.props.setdisabledBottonPopup(false)
                       }else{
                         this.props.setdisabledBottonPopup(true)
                       }
                     }}
                   />
                 </div>: 
                this.props.checkbox == 4 || this.props.checkbox == 5?
                <div style={{marginTop:"5px",marginBottom:15}}>   
                  <div className="tempModalTxt">
                  <Dropzone onDrop={(e)=>this.handleDrop(e)}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <div className="uploadWrapContainer">
                          <div className="drop-it-hot" id="drop-area">
                            <form className="choose-files">
                              <svg
                                fill="currentColor"
                                height="25"
                                viewBox="0 0 24 24"
                                width="25"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ color: '#BFBFBF' }}
                              >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
                              </svg>
                              <div className="button-wrapper" style={{marginTop:10}}>
                                <label className="label" for="fileElem" style={{ width: '31%', textAlign: 'center', fontWeight:'bold', color:'black' }}>
                                  Drag and drop file or
                                </label>
                                <div style={{ position: 'relative', height: '40px', marginBottom: '15px' }}>
                                  {/* <input  style={{ display: 'none' }}  class="reimbursement-ButtonBGBrowse" id="raised-button-file" accept=".jpg,.png,.doc,.pdf,.zip" onChange={(event)=>this.handleChangeFile(event)} onClick={e => (e.target.value = null)} multiple type="file" /> */}
                                  <label htmlFor="raised-button-file">
                                    <Button variant="raised" component="span" className="r-btn" >
                                      Browse
                                    </Button>
                                  </label>
                                  <center className="wordWrap" style={{color: 'green', cursor: 'pointer'}}>{this.state.name? this.state.name : 
                                
                                    (this.props.superBill && this.props.checkbox == 4)?
                                    <p onClick={()=> window.open(this.props.superBill)}>{this.props.superBill && this.props.superBill.path ? this.props.superBill.path  : this.props.superBill}</p>
                                    : 
                                    (this.props.proof && this.props.checkbox == 5) ? 
                                    <p onClick={()=> window.open(this.props.proof)}>
                                    {this.props.proof && this.props.proof.path? this.props.proof.path :this.props.proof} </p>: null
                                  }</center>
                                  <br/>
                                 {this.state.size>25 && <center style={{color:"red", marginBottom:3}}>File size exceeds the maximum limit.</center>}
                                  {(this.state.type !== null && (
                                    this.state.type != "pdf" && 
                                    this.state.type != "zip" &&
                                    this.state.type != "png" &&
                                    this.state.type != "jpg" &&
                                    this.state.type != "jpeg" &&
                                    this.state.type != "vnd.openxmlformats-officedocument.wordprocessingml.document")) && <center style={{color:"red", marginBottom:3}}>File type not supported.</center>}
                                  <div style={{textAlign:"center" , fontWeight:'500'}}>Maximum file size: 25MB<br/> Supported file types: JPG,PNG,DOC,PDF,ZIP</div>
                                </div>

                                <div style={{ height: '80px' }}></div>
                              </div>
                            </form>
                            <div id="gallery"></div>
                          </div>
                        </div>  
                    </div>
                  )}
                    </Dropzone>
                  </div>
                 </div>:  
                 <div style={{marginTop:"5px",marginBottom:15}}>
                 <CssTextField
                     label="Amount Charged"
                     required id="outlined-name"
                     variant="filled"
                     type="number"
                     autoComplete='off'
                      InputProps={{
                        startAdornment: <p style={{marginTop:33, marginRight:1}}>$</p>
                      }}
                     value={this.props.AmountCharged}
                     onChange={(e)=>{ 
                       this.props.setAmountCharged(e.target.value)
                       if(this.props.checkbox == 6 && this.props.AmountCharged!==null && this.props.paidAmount!==null){
                         this.props.setdisabledBottonPopup(false)
                       }else{
                         this.props.setdisabledBottonPopup(true)
                       }
                     }}
                   />
                   <div style={{marginTop:"30px"}}> 
                   <CssTextField
                     label="Amount Paid"
                     required id="outlined-name"
                     variant="filled"
                     type="number"
                     value={this.props.paidAmount}
                     InputProps={{
                      startAdornment: <p style={{marginTop:33, marginRight:1}}>$</p>
                    }}
                     onChange={(e)=>{ 
                       this.props.setpaidAmount(e.target.value)
                       if(this.props.checkbox == 6 && this.props.paidAmount!==null && this.props.AmountCharged!==null){
                         this.props.setdisabledBottonPopup(false)
                       }else{
                         this.props.setdisabledBottonPopup(true)
                       }
                     }}
                   />
                   </div>
                 </div>
              }
                </Grid>
              </Grid>
             
              
        
            </Modal.Body>

          <Modal.Footer>
           
              <>
                <Button variant="secondary" style={{ marginRight: '15px' }} 
                onClick={() => {this.props.handleCANCEL(this.props.checkbox); this.setState({name:"", type:null, size:null })}}
                class="reimbursement-ButtonBGcannel">
                  CANCEL
                </Button>
          
                {this.props.checkbox == 4 || this.props.checkbox == 5? 
                <Button variant="secondary" 
                  disabled={(this.state.name == "" && ((this.props.checkbox == 4 && this.props.superBill == null) || (this.props.checkbox == 5 && this.props.proof == null))) 
                  || this.state.size>25 || (this.state.type !== null && (
                    this.state.type != "pdf" && 
                    this.state.type != "zip" &&
                    this.state.type != "png" &&
                    this.state.type != "jpg" &&
                    this.state.type != "jpeg" &&
                    this.state.type != "vnd.openxmlformats-officedocument.wordprocessingml.document"))} 
                  style={{ marginRight: '15px' }} 
                  onClick={() => this.onFileUpload(this.state.name == "" && ((this.props.checkbox == 4 && this.props.superBill !== null) || (this.props.checkbox == 5 && this.props.proof !== null)))} 
                  class={((this.state.name == "" && ((this.props.checkbox == 4 && this.props.superBill == null) || (this.props.checkbox == 5 && this.props.proof == null))) 
                  || this.state.size>25 || (this.state.type !== null && (
                    this.state.type != "pdf" && 
                    this.state.type != "zip" &&
                    this.state.type != "png" &&
                    this.state.type != "jpg" &&
                    this.state.type != "jpeg" &&
                    this.state.type != "vnd.openxmlformats-officedocument.wordprocessingml.document"))) ?"reimbursement-ButtonBGsaveDisabled":"reimbursement-ButtonBGsave"}
                  >
                  SAVE
                </Button>:
                <Button variant="secondary" disabled={disable} style={{ marginRight: '15px' }} onClick={() => this.props.submitModal(this.props.checkbox)} class={disable?"reimbursement-ButtonBGsaveDisabled":"reimbursement-ButtonBGsave"}>
                  SAVE
                </Button>}
              </>
      
          </Modal.Footer>
        </Modal></div>
        )
    }
    dataURItoBlob(dataURI){
      return dataURI;
    }
}
export default ReimbursementModal1