import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import AccordionViewModal from '../MobileCommonComponents/AccordionViewModal'
import '../MobileCommonComponents/SimpleAccordion.css'
const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    padding: '0px',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#ffffff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 120,
    paddingLeft: '5px',
    paddingRight: '5px',
    '&$expanded': {
      minHeight: 125
    }
  },

  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails)
export default class ReimbursementDataMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: '',
      formatedData: props.tableData,
      showModal: false,
      modalData: null,
      expansionPanelOpen: false
    }
    console.log('Accordion pros', this.state.formatedData)

    console.log('=========props data isss==========', this.props)
  }

  handleChange = panel => (event, expanded) => {
    console.log('expanded====', panel, expanded)
    this.setState({ expanded: expanded ? panel : false })
  }

  openModalInfo(dataIndex) {
    this.setState({
      showModal: true,
      modalData: this.state.formatedData[dataIndex]
    })
  }

  closeModalInfo() {
    this.setState({
      showModal: false
    })
  }

  //--- To get updated props on loadMore Btn click
  componentWillReceiveProps(nextProps) {
    this.setState({ formatedData: nextProps.tableData })
  }

  getDate(date) {
    const isDateValid = moment(date)['_isValid']
    if (isDateValid) {
      return moment(date).format('MMMM D, YYYY')
    }
    return date
  }

  numberWithCommas(x) {
    if (x) {
      if (x.toString().startsWith('$')) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    } else {
      return 'NA'
    }
  }

  floatconversion = x => {
    if (x) {
      if (x.toString().startsWith('$')) {
        let substr = x.substr(1, x.length)
        return (
          '$' +
          parseFloat(substr)
            .toFixed(2)
            .toString()
        )
      } else {
        return x.toFixed(2)
      }
    } else {
      return undefined
    }
  }

  render() {
    return (
      <div>
        {this.state.formatedData && this.state.formatedData.map((data, index) => (
          <Accordion expanded={this.state.expanded === 'panel' + index} onChange={this.handleChange('panel' + index)}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="panelSummary"
            >
              <Grid container style={{paddingLeft:10}}>
                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Reference #</div>
                  <div className="Expense-Number211">{data.id}</div>
                </Grid>

                <Grid item xs={6} className="itemWrap">
                    {data.status !== "PENDING" ? (
                    <span className={'status_button Audit'}>{data.status}</span>
                    ) : (
                    <span className={'status_button Logged'}>{data.status.replace('PENDING', 'NOT YET SUBMITTED')}</span>
                    )}
                </Grid>
              

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Member</div>
                  <div className="Expense-Number211">{data.memberName? data.memberName: "NA"}</div>
                </Grid>

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Date Of Service</div>
                  <div className="Expense-Number211">{data.serviceDate?this.getDate(data.serviceDate):"NA"}</div>
                </Grid>

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Provider</div>
                  <div className="Expense-Number211">{data.providerName ? data.providerName : "NA"}</div>
                </Grid>
                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Charged Amount</div>
                  <div className="Expense-Number211">{data.chargedAmount? "$"+ data.chargedAmount :"NA"}</div>
                </Grid>

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Paid Amount</div>
                  <div className="Expense-Number211">{data.paidAmount? "$" + data.paidAmount : "NA"}</div>
                </Grid>
                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Submitted /Last Edit Date</div>
                  <div className="Expense-Number211">
                  {data.createdDate? this.getDate(data.createdDate):"NA"}
                  </div>
                </Grid>

              

              </Grid>
              <Grid item xs={1} className="itemWrap" style={{marginRight:10}}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                        <React.Fragment>
                                        <IconButton {...bindTrigger(popupState)}>
                                            <MoreVertIcon/>
                                        </IconButton>
                                        <Menu {...bindMenu(popupState)} onClick={popupState.close}
                                                getContentAnchorEl={null}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center'
                                                }}
                                                transformOrigin={{
                                                    vertical: -10,
                                                    horizontal: 30
                                                }}>
                                            <MenuItem onClick={() => this.props.update(data.memberId, data.id)}>Update</MenuItem>
                                        </Menu>
                                        </React.Fragment>
                                    )}
                            </PopupState>
                </Grid>
            </AccordionSummary>
       
          </Accordion>
        ))}

      
      </div>
    )
  }
}

const sampleData2 = [
  { id: 733, planId: '7006', idcardField: 'Telemedicine', fieldValue: '$0' },
  { id: 735, planId: '7006', idcardField: 'PCP Office Visit', fieldValue: '$35' },
  { id: 736, planId: '7006', idcardField: 'Urgent Care', fieldValue: '$100' },
  { id: 737, planId: '7006', idcardField: 'ER Visit', fieldValue: '$400' }
]
