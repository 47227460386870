//aws amplfy
import Amplify, { Auth } from 'aws-amplify';
import { ConnectedRouter } from 'connected-react-router';
// import { Store } from 'redux'
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import awsconfig from './aws-exports';
// import { ApplicationState } from './store'
// import LayoutContainer from './containers/LayoutContainer'
// import * as themes from './styles/theme'
import CustomAuth from './components/authentication/CustomAuth';
import firebase from './firebase';
// import { ThemeProvider } from 'emotion-theming'
import Routes from './routes';
import { getDecryptIdAPI, saveUserNotification } from './source/ApiCall';
import axios from 'axios';
import { getProperEmailId, getUserDetails } from './actions/homeActions';
import Loader from './source/CommonLoader';

Amplify.configure(awsconfig);


// Any additional component props go here.
interface MainProps {
  store: any
  history: History
}


// Create an intersection type of the component props and our Redux props.
const Main: any = ({ store, history }: any) => {

  // let route_history = useHistory();
  // let location = useLocation();
  const [isLogged, setIsLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState(false)


  window.addEventListener('storage', function(event) {
    if (event.key === 'logoutbuttonClicked') {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace('/login')
    }
  });

  useEffect(() => {
    const urlcheck = new URL(window.location.href);
    if (!urlcheck.href.includes("HipaaAuthorization")){
        const handleStorageChange = (event: any) => {
          if (event.key === 'userMail' && sessionStorage.getItem("launch")) {
            window.location.reload();
          } else if(event?.url?.split("=")?.length > 1){
            let url =  event?.url?.split("=")[1];
            if(url !==  sessionStorage.getItem("urls")){
              if(!event.url.includes("HipaaAuthorization"))
              window.location.href = event.url;
            }
          }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
    }
  }, []);

  useEffect(() => {
    //const msg=firebase.messaging()
    sessionStorage.setItem("chatwindow", 'false');
    const msg = firebase.messaging.isSupported() ? firebase.messaging() : null;

    if(msg){
      msg.requestPermission().then(()=>{
        return msg.getToken()
      }).then(token=>{
        localStorage.setItem("TOKEN_TYPE",'web')
        localStorage.setItem("firebaseToken",token)
        console.log("Fireabse token iss===",token);
      });

      msg.onMessage(payload => {
        console.log("onMessage:+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ ", payload);
      });
    }
    let url = window.location.href;
    const urlcheck = new URL(url);
    if ( url.split("=").length > 1 && !urlcheck.href.includes("HipaaAuthorization")) {
      setLoader(true)
      localStorage.clear();
      sessionStorage.clear();
      getGateWayToken(url.split("=")[1]);
      sessionStorage.setItem("urls", url.split("=")[1]);
      sessionStorage.setItem("launch", "true");
    } else if(sessionStorage.getItem('USER_ACTIVE_SESSION') === 'true'){
      setIsLogged(true)
      setIsLoading(false)
      localStorage.setItem('isLogged', 'true');
    } else {
      Auth.currentSession().then((session: any) => {
        parseJwt(session.idToken.jwtToken)
        setIsLogged(true)
        setIsLoading(false)
        localStorage.setItem('isLogged', 'true');
        saveUserNotificationDetails()
      }).catch((error: any) => {
        setIsLogged(false)
        setIsLoading(false)
        localStorage.setItem('isLogged', 'false')
      })
    }
  }, []);

  const getGateWayToken = (params: any) => {
    let request = {
        "username": process.env.REACT_APP_GETWAY_TOKEN_USERNAME,
        "password": process.env.REACT_APP_GETWAY_TOKEN_PASS
        // "password": "x1TXVUtXL6PaBWam"
    }
    let netwellOrganization = Buffer.from(`NETWELL;${new Date()}`).toString('base64')
    axios.post(process.env.REACT_APP_BASE_URL + "v1/login", request,
        {headers : {
          'X-Tenant-Id': netwellOrganization,       
        }}).then(data=>{
        let bearer = data['headers'].authorization
        var array = bearer.split("Bearer ")
        console.log('newBearer - - - ', array);
        localStorage.setItem("bearerToken", array[1]);
        let decodeurl = decodeURIComponent(params);
        getDecodeData(decodeurl);
    })
}
const getDecodeData = async (data: any) => {
  await getDecryptIdAPI(data).then((resp :any) =>{
     getuserData(resp);
  }).catch(err=>{
     console.log("went wrong:", err)
  })
}
const getuserData = async (data: any) => {
  try {
      let email : any = await getProperEmailId(data);
      let userData : any = await getUserDetails(email)
      if (userData) {
        setLoader(false)
          let find = userData.data.memberIdCardList[userData.data.memberIdCardList.length - 1]
          localStorage.setItem('SOURCE', find.source)
          localStorage.setItem("sourceid",userData.data.memberIdCardList[0].memberId)
          localStorage.setItem('CLIENT_ID', find.clientId)
          localStorage.setItem('planId', userData.data.memberIdCardList[0].planId)
          localStorage.setItem('userMail', email)
          sessionStorage.setItem('USER_ACTIVE_SESSION', "true");
          if (userData.data.memberIdCardList[0].guardian) {
              localStorage.setItem('isGuardian', "true")
              localStorage.setItem('guardianName', userData.data.memberIdCardList[0].guardian.firstName+" "+userData.data.memberIdCardList[0].guardian.lastName)
            }
          localStorage.setItem('subscriberName', userData.data.memberIdCardList[0].firstName+" "+userData.data.memberIdCardList[0].lastName)
          let contact = userData.data.memberIdCardList[userData.data.memberIdCardList.length - 1].planInfo.find(
          (obj: any)  => obj.idcardField == 'contact number'
          )
          contact ? localStorage.setItem('CONTACT_NUMBER', contact.fieldValue) : localStorage.setItem('CONTACT_NUMBER', 'NA');
          window.location.href = '/';
      }
  } catch (err){
      window.location.href = '/login';
      setLoader(false);
  }
}
  const saveUserNotificationDetails = () => {
    let email=localStorage.getItem('userMail')
    let phone=localStorage.getItem('phone')
    let username=localStorage.getItem('subscriberName')
    let firebase=localStorage.getItem('firebaseToken')
    let type=localStorage.getItem('TOKEN_TYPE')
    let client_id=localStorage.getItem("CLIENT_ID")
    let obj={
      "email":email,
      "phone":phone,
      "username":username,
      "token":firebase,
      "type":type,
      "clientId":client_id
    }
    console.log("Main tsx saveUserObject=====",obj)
    saveUserNotification(obj)
      .then(res=>{
        console.log("seva user++++++++")
      })
  }

  const parseJwt = (token: any) => {
    // console.log('inside parse')
    // console.log('token:' + token)
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    var token = JSON.parse(jsonPayload)
    // console.table(token)
    localStorage.setItem('userMail', token.email)
    // localStorage.setItem('subscriberName', token.name)
    localStorage.setItem('phone', token.phone_number)
    localStorage.setItem('cognitoUsername', token['cognito:username'])
    localStorage.setItem('isLogged', 'true');
    if(localStorage.getItem('popupShow') && localStorage.getItem('popupShow') === 'false' ){
      localStorage.setItem('popupShow', 'false')
    } else {
      localStorage.setItem('popupShow','true');
    }

    if(localStorage.getItem('healthQuestionModal') && localStorage.getItem('healthQuestionModal') === 'false' ){
      localStorage.setItem('healthQuestionModal', 'false')
    } else {
      localStorage.setItem('healthQuestionModal','true');
    }

    return token.email;
  }

  if (loader) {
    return (
      <Loader/>
    )
  }
  else {
    if (!isLogged) {
      return (
        <Provider store={store} >
          <ConnectedRouter history={history}>
            <CustomAuth />
          </ConnectedRouter>
        </Provider >

      )
    } else {
      return (
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
        </Provider>
      )
    }
  }



}

// Normally you wouldn't need any generics here (since types infer from the passed functions).
// But since we pass some props from the `index.js` file, we have to include them.
// For an example of a `connect` function without generics, see `./containers/LayoutContainer`.
export default Main
// export default withAuthenticator(Main, true);
