import Button from '@material-ui/core/Button'
import React, { useEffect, useState } from 'react'
import { deviceType, isMobile, isTablet } from 'react-device-detect'
import WebFooter from '../../source/WebScreen/WebFooter'
import './Login.css'
import styleJson from '../../source/styleJSON'
const ForgotPasswordForm = props => {
  const [defaultValue, setDefaultValue] = useState('')

  useEffect(() => {}, [])
  const [netWellUser] = useState(process.env.REACT_APP_NETWELLUSER == "true"? true :false)
  const [styles] = useState(process.env.REACT_APP_NETWELLUSER == "true"? styleJson.NetWell :styleJson.UHF)
 
  const handleSendVerification = e => {
    if (e) {
      e.preventDefault()
    }
    props.handleSendVerification()
  }

            
            
  const handleKeyUp = e => {
    if (e.keyCode == 9) {
      e.preventDefault()
          }
  }

  return (
    <div>
      {deviceType == 'browser' && (isMobile == false || isMobile == 'false') && (isTablet == false || isTablet == 'false') ? (
        <div class="loginformnew">
          <form onSubmit={handleSendVerification}>
            <div  className={styles.loginform_containernew}>
           
              <div className="loginform_containernetwell">
                <div class="h_left_icon_div" >
                  <img src={require('../../source/Images/HomeIcon/netwell-logo.png')} className="netwell_logo"/>
                </div>
                  <img src={require('../../source/Images/Banner/welcome_image_netwell.png')} className="login_main_image_netwell" />
              </div>
              

              <div class="login_input_container">
                <div class={styles.checkregister_blue_text_container}>
                  <div class="checkregister_having_text">Forgot your password?</div>
                  <div class="checkregister_having_subtext">Enter your Email and we will send a</div>
                  <div class="checkregister_having_subtext">message to reset your password.</div>
                </div>
                <div class="forgot-input-con">
                  <input
                    class="login_input_username"
                    type="email"
                    name="email"
                    pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
                    required
                    placeholder="ENTER YOUR EMAIL"
                    className="login_input_username"
                    onChange={props.handleChange}
                    onKeyDown={handleKeyUp}
                  />

                  <Button type="submit" class={styles.checkregister_button}>
                    RESET MY PASSWORD
                  </Button>
                </div>
              </div>

              <div class="checkregister_goto_subtext">
                Go back to
                <span class={styles.rege_goto} onClick={() => props.gotoLoginScreen()}>
                  {' '}
                  Sign In
                </span>
              </div>
            </div>
          </form>
          <WebFooter />
        </div>
      ) : (
        <div class="loginmobile">
          <div class="loginmobile_header_netwell">
            {/* <MenuIcon style={{ color: '#ffffff', marginRight: '20px' }} /> */}
            <div class="loginmobile_header_text_netwell">Member Portal</div>
          </div>

         
            <form onSubmit={handleSendVerification}>
              <div class="loginmobile_image_container">
              <img src={require('../../source/Images/HomeIcon/netwell-logo.png')} className="netwell_logo_mobile"/>
              <img src={require('../../source/Images/Banner/family-looking-at-tablet_1.jpeg')} className="login_main_image_netwell" />
              </div>
              <div class="loginmobile_container">
              <div class="loginmobile_input_container">
                <input
                  type="email"
                  name="email"
                  pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
                  required
                  placeholder="ENTER YOUR EMAIL"
                                    class="loginmobile_input_username"
                  onChange={props.handleChange}
                />

                <Button type="submit" variant="contained" color="" class="loginmobile_button_netwell">
                  RESET MY PASSWORD
                </Button>
              </div>

              <div class="loginmobile_bottom_text">
                <div class="loginmobile_forgot_text_netwell">Forgot your password?</div><br/>
                <div class="forgotmobile_text_details">
                  <div>Enter your Email and we will send a message to</div>
                  <div>reset your password</div>
                </div>
                <div class="loginmobile_bottom_subtext2">
                  <div>
                    Go to
                    <span class="loginmobile_please_check_netwell" onClick={() => props.gotoLoginScreen()}>
                      {' '}
                      Sign In
                    </span>
                  </div>
                </div>
              </div>
              </div>
            </form>
          </div>
        
      )}
    </div>
  )
}

export default ForgotPasswordForm





