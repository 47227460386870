import { Box, Grid, Hidden, Tab, Tabs } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { createMuiTheme, makeStyles, ThemeProvider, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import CloseIcon from '@material-ui/icons/Close'
import ForumIcon from '@material-ui/icons/Forum'
import axios from 'axios'
import moment from 'moment'
import React, { Component, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import {
  getAgentInfo,
  getCardEnableData,
  getClientDetails,
  getEncryptData,
  getEnrolledMemberData,
  getEnrollMemberInfoById,
  gethealthcard,
  getProgramInfo,
  getAddOnData,
  getSourceID
} from '../../ApiCall'
import CommonLoader from '../../CommonLoader'
import MobCopyright from '../../MobileScreen/MobCopyright'
import MobileFooter from '../../MobileScreen/MobileFooter'
// import '../../MobileScreen/MobileFooter.css'
import ChatIcon from '../ChatBox/ChatIcon'
import CommonFooter from '../CommonFooter'
import Header from '../Header'
import customStyle from './CSS/stylesheet_UHS'
import i18n from './i18next'
import './ProgramInformation.css'
import styleJson from '../../styleJSON';

const CrudButton = withStyles(customStyle.crudBtn)(Fab)
const CrudButtonNetwell = withStyles(customStyle.crudBtnNetwell)(Fab)
const NextButton = withStyles(customStyle.ChangePayButton)(Button)

window.mobileAndTabletCheck = function() {
  let check = false
  ;(function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

const TableHeadCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '8px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',

    letterSpacing: '1.12px',
    textAlign: 'left',
    color: '#454d58'
  }
})(TableCell)

const TableBodyCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '8px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',

    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000'
  }
})(TableCell)

const AntTabs = withStyles(customStyle.ProgTabs)(Tabs)
// const NextButton = withStyles(customStyle.PayButton)(Button)

class ProgramInformation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loader: false,
      sampleData: sampleData,
      barRange: '0',
      barRangeYellow: '0',
      acsmTotal: '',
      acsmmet: '',
      acsmremain: '',
      nsamet: '',
      nsaremain: '',
      programname: '',
      programname2: '',
      showwithacsm: false,
      clientID: '',
      associationId: '',
      clientName: '',
      brokerId: '',
      subID: '',
      rxSimpleShareActive: false,
      addOnsObj: [],
      counterDental: 0,
      enrolledDataMember: [],
      selectedTab:"summary",
      netWellUser: process.env.REACT_APP_NETWELLUSER == "true"? true :false,
      styles: process.env.REACT_APP_NETWELLUSER == "true"? styleJson.NetWell :styleJson.UHF,
      ProgramInfoData:null,
      rxSimpleShareActive: false,
      counterDental: 0,
    }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  componentDidMount() {
    this.setState({
      loader: true
    })
    axios.get(process.env.REACT_APP_BASE_URL_VERSION+"memberportal/getProgramInformation/"+localStorage.getItem('userMail')).then((response)=>{
      this.setState({ProgramInfoData: response.data,  loader: false});
      this.getEnrollMember(localStorage.getItem("sourceid"))
      this.getAddonsData()
    })
  }

  getAddonsData = () => {

    gethealthcard().then(resp => {
      let data = {
        memberSSN: resp.data.memberIdCardList[0].memberSSN,
        type: 'family'
      }
      getAddOnData(data).then(res => {
        this.setState(
          {
            addOnsObj: res
          },
          () => {
            let counterDental = 0

            this.state.addOnsObj.forEach(item => {
              counterDental = item.addon.includes('NWS') ? counterDental + 1 : counterDental
            })

            let findRxSimpleData = res.find(obj => obj.addon.includes('NWS') && obj.status === 'AC')
            if (findRxSimpleData) {
              this.setState({
                rxSimpleShareActive: true,
                counterDental: counterDental
              })
            }
          }
        )
      })
    })
  }

  getEnrolledMemberData = () => {
    getEnrolledMemberData().then(res => {
      if (res && res.data) {
        this.setState({
          enrolledDataMember: res.data
        })
      }
    })
  }

  jumpToAddons() {
    let url = window.location.href
    if (url.split('ProgramInformation?').length > 1) {
      let queryString = url.split('ProgramInformation?')[1]
      let queryParams = new URLSearchParams(queryString)
      let addons = decodeURI(queryParams.get('change_addons'))
      if (addons === 'true') {
        this.changeProgram()
      } else {
        this.updateHousehold()
      }
      this.props.history.replace('/ProgramInformation')
    }
  }
  getCardEnable = () => {
    let client_id = localStorage.getItem('CLIENT_ID')

    // getCardEnableData(client_id, 'ProgramInformation').then(res => {
    //   if (res.data.response.enable == 'false' || res.data.response.enable == false) {
    //     window.location.href = '/'
    //   }
    // })
  }

  getSourceIDbyemail() {
    getSourceID().then(res => {
      this.agentInfoget(res.data.memberIdSource)
      
    })
  }

  agentInfoget(sourceid) {
    if (sourceid) {
      getAgentInfo(sourceid).then(res => {
        if (res.data.response) {
          this.setState({
            agentemail: res.data.response.email,
            agentname: res.data.response.name,
            agentno: res.data.response.phone,
            brokerId: res.data.response.brokerId
          })
        } else {
        }
      })
    }
  }
  getEnrollMember = memberId => {
    getEnrollMemberInfoById(memberId).then(response => {
      if (response && response.data.response) {
        this.setState({ subID: response.data.response.subId, 
          brokerId: response.data.response.brokerId,
          loader: false })
      } else {
        this.setState({ loader: false })
      }
    })
  }

  changeProgram = () => {
    let obj = {
      clientId: this.state.clientName,
      associationId: this.state.associationId,
      brokerId: this.state.brokerId,
      fromMember: true,
      isSelectProgram: true,
      user_subId: localStorage.getItem('userMail'),
      memberId: localStorage.getItem('sourceid'),
      subID: this.state.subID
    }
    if (this.state.subID === null || this.state.subID === undefined || this.state.subID === '') {
      this.setState({
        errorModal: true
      })
    } else {
      let windowReference = window.open()
      getEncryptData(obj).then(response => {
        let URL = process.env.REACT_APP_LOGIN_ENROLLMENT + 'login#state=' + response.data.response
        windowReference.location = URL
      })
    }
  }

  updateHousehold = () => {
    let obj = {
      clientId: this.state.clientName,
      associationId: this.state.associationId,
      brokerId: this.state.brokerId,
      fromMember: true,
      user_subId: localStorage.getItem('userMail'),
      isHouseholdUpdate: true,
      memberId: localStorage.getItem('sourceid'),
      subID: this.state.subID,
      isEditCensus: false
    }
    if (this.state.subID === null || this.state.subID === undefined || this.state.subID === '') {
      this.setState({
        errorModal: true
      })
    } else {
      let windowReference = window.open()
      getEncryptData(obj).then(response => {
        let URL = process.env.REACT_APP_LOGIN_ENROLLMENT + 'login#state=' + response.data.response

        windowReference.location = URL
      })
    }
  }

  goBack = () => {
    this.props.history.push('/')
  }

  handleCloseErrorModal = () => {
    this.setState({
      errorModal: false
    })
  }
  selectTab=(newValue)=>{
    this.setState({selectedTab: newValue})
  }
  render() {
    return (
      <div className="progInfoMainWrapper">
        {this.state.loader ? <CommonLoader /> : null}

        <div className="mobileViewHeader">
          <div className={this.state.netWellUser?"mobile_headerNetWell":"mobile_header"}>
            <Link to="/">
              <ArrowBackIcon style={{ width: '24px', height: '24px', color:this.state.netWellUser?'#000000': '#ffffff' }} />
            </Link>
            <div className={this.state.netWellUser?"mobile_header_titleNetWell":"mobile_header_title"}>Program Information </div>
          </div>
        </div>

        <div className="webHeader">
          <Header name={'Program Information'} />
        </div>

        <div className="container progInfoContainer">
          <sapn className={this.state.styles.backpage} onClick={this.goBack}>
            <ArrowBackIcon style={{ width: '24px', height: '24px', color: this.state.netWellUser?"#4782c4":'#162242', marginRight: '5px' }} onClick={this.goBack} />
            BACK
          </sapn>

          <div className="commonWrap">
            <ProgramInfoCard netWellUser={this.state.netWellUser} state={this.state} selectTab={(newValue) => this.selectTab(newValue)} />
            <div className="progInfoFooter" style={{ paddingTop: '0px', display: window.mobileAndTabletCheck() ? 'none' : '' }}>
              <div className="row">
                <div className="col-md-12 dpFlex">
                  <div className="footerText" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <div>
                      <p>Need help?</p>
                      <p>Call our netWell Representative</p>
                      <p>on (866) NETWELL (638-9355).</p>
                    </div>
                    <div style={{ marginLeft: 12 }} className="">
                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobileFooter">
            <div style={{ bottom: '9vh', paddingBottom:
                     this.state.selectedTab==="enrolledMembers" || this.state.selectedTab==="addOns"?
                      "15vh":"7vh"
                  }}>
              <ChatIcon 
                shiftup={ this.state.selectedTab==="enrolledMembers" || this.state.selectedTab==="addOns"? true : false} 
                changeProgram={this.state.selectedTab==="summary"? true:false}
                showAIChatIcon={true} />
              <div>
                <ChatIcon />
              </div>
              <div>
                <MobCopyright />
              </div>
            </div>
          </div>
          <div className="fixed-bottom" style={{ visibility: window.mobileAndTabletCheck() ? '' : 'hidden' }}>
            <MobileFooter />
          </div>
        </div>

        <div className="webFooter" style={{margin:"40px 0px 0px"}}>
          <CommonFooter />
        </div>

        {/* ===============================Error Modal====================================== */}

        <Modal size="small" show={this.state.errorModal} onHide={this.handleCloseErrorModal} centered backdrop="static">
          <Modal.Header>
            <Modal.Title>Message</Modal.Title>
            <IconButton aria-label="close" onClick={this.handleCloseErrorModal} style={{ marginTop: '-13px' }}>
              <CloseIcon />
            </IconButton>
          </Modal.Header>

          <Modal.Body>
            <b> {i18n.t('PROGRAM_INFORMATION_ERROR_MESSAGE.SUBID_MISSING')}</b>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseErrorModal} class="ButtonBG">
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
export default withRouter(ProgramInformation)

const names = ['James', 'Paul', 'John', 'George', 'Ringo']
const sampleData = [
  { id: 733, planId: '7006', idcardField: 'Telemedicine', fieldValue: '$0' },
  { id: 735, planId: '7006', idcardField: 'PCP Office Visit', fieldValue: '$35' },
  { id: 736, planId: '7006', idcardField: 'Urgent Care', fieldValue: '$100' },
  { id: 737, planId: '7006', idcardField: 'ER Visit', fieldValue: '$400' }
]

const ProgramInfoCard = props => {
  const tabInQuery = window.location.href.includes('changeDependants') ? 'enrolledMembers' : 'summary'
  const [selectedTab, setSelectedTab] = useState('summary')
  const history = useHistory()

  const openChat = () => {
    sessionStorage.setItem('chatwindow', true)
    history.push('/')
  }
  const selectTab =(newValue)=>{
    setSelectedTab(newValue)
    props.selectTab(newValue)
  }
  return (
    <div className="progInfoSection" style={{ paddingBottom: '0.5px' }}>
      <h2 className="progInfoTitle" style={{ color: props.netWellUser?"#162242":'#162242',}}>Program Information</h2>
      <Box sx={{ width: '100%' }}>
        <AntTabs
          variant="scrollable"
          value={selectedTab}
          onChange={(e, newValue) => selectTab(newValue)}
          textColor="#33333"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          // variant="scrollable"
          id="tabs"
          style={{ width: '100%' }}
        >
          <Tab label="Summary" value="summary" style={{ textTransform: 'capitalize',width:'13%' }} />
          <Tab label="Visit Fees" value="eligibleServices" style={{ textTransform: 'capitalize',width:'20%', }} />
          <Tab label="Sharing Limits" value="expenseLimits" style={{ textTransform: 'capitalize',width:'25%' }} />
          <Tab label="Add-Ons" value="addOns" style={{ textTransform: 'capitalize',width:'25%' }} />
          <Tab label="Enrolled Members" value="enrolledMembers" style={{ textTransform: 'capitalize',width:'20%' }} />
          <Tab label="Discount Programs" value="DiscountPrograms" style={{ textTransform: 'capitalize',width:'20%' }}/>
        </AntTabs>

        {selectedTab === 'summary' && <SummaryTabContent props={props} />}
        {selectedTab === 'eligibleServices' && <EligibleServiceTabContent props={props} />}
        {selectedTab === 'expenseLimits' && <ExpenseLimitTabContent props={props} />}
        {selectedTab === 'addOns' && <AddOns props={props} />}
        {selectedTab === 'enrolledMembers' && <EnrolledMembersTabContent props={props} />}
        {selectedTab === 'DiscountPrograms' && <DiscountPrograms props={props} />}
      </Box>
    </div>
  )
}

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const SummaryTabContent = ({ props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [msgModal, setmsgModal] = useState(null)
  const [loader, setLoader] = useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)
  const closeSuccessModal = () => setIsSuccess(false)
  const setModalValue = val => {
    if (val == 'PROCEED') {
      setLoader(true)
      let obj1 = {
        Subject: localStorage.getItem('sourceid'),

        Origin: 'External',

        External_Application_Name__c: 'Member Portal',

        Status: 'New',

        Type: 'Account Update',

        SuppliedEmail: localStorage.getItem('userMail'),

        Description: 'Request to change'
      }

      let url = process.env.REACT_APP_BASE_URL + 'v1/memberportal/caseCreation'

      axios
        .post(url, obj1, {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('bearerToken')
          }
        })
        .then(response => {
          if (response.data.success == 'true' || response.data.success == true) {
            setmsgModal('PROCEED')
            setIsOpen(false)
            setIsSuccess(true)
            setLoader(false)
          }
          // this.setState({errorModal:true,open:false,loader:false,MsgModal:'Request send successfully!'})
        })
    }
    if (val == 'CANCEL') {
      setLoader(true)
      setmsgModal('CANCEL')
      setIsOpen(false)
      setIsSuccess(true)
      setLoader(false)
    }
    // setIsOpen(false)
  }
  if(props.state.ProgramInfoData)
  return (
    <div className="row sumary" style={{height:window.mobileAndTabletCheck?"60vh": ""}}>
      <div className="col-12 col-md-4" style={{ margin: '0px 0px 30px 0px' }}>
        <h1 className="logoFont UHS6">{props.state.ProgramInfoData.planInfo[0].fieldValue}</h1>
        <h1 className="logoFont ACSM">{props.state.ProgramInfoData.planInfo[0].planId}</h1>
      </div>
      <div className="col-12 col-md-4 headerSumary">
      { false && <h6 className="infoHeading">Summary</h6>}

          {false && <div className="progessBarWrapper">
            <div className="barTitle">{"Member Commitment Portion"}</div>
            <div className="progressContainer">
              <div className="progressIndicator" style={{ width: props.state.ProgramInfoData.nsa.met/props.state.ProgramInfoData.nsa.remaining*100+"%" }}>
                <ArrowRightAltIcon viewBox="0 6 24 24" style={{ color: '#ffffff' }} />
              </div>
            </div>

            <div className="amtWrapper">
              <span className="metAmt">${numberWithCommas(props.state.ProgramInfoData.nsa.met)} met</span>
              <span className="remainingAmt">${numberWithCommas(props.state.ProgramInfoData.nsa.remaining)} remaining</span>
            </div>
          </div>}


        <div className="progessBarWrapper">

            {props.state.ProgramInfoData.msl && false && <>
              <div className="barTitle">Maximum Sharing Limit</div>
              <div className="progressContainer">
                <div className="progressIndicator" style={{ width: props.state.ProgramInfoData.msl.met/props.state.ProgramInfoData.msl.remaining + '%' }}>
                  <ArrowRightAltIcon viewBox="0 6 24 24" style={{ color: '#ffffff' }} />
                </div>
              </div>

              <div className="amtWrapper">
                <span className="metAmt metAmtYellow">${numberWithCommas(props.state.ProgramInfoData.msl.met)} met</span>
                <span className="remainingAmt">${numberWithCommas(props.state.ProgramInfoData.msl.remaining)} remaining</span>
              </div>
            </>}

        </div>
      </div>
      {/* {window.mobileAndTabletCheck() ? (
        <Grid container className="Bottom-Blue">
          <Grid item xs={12} style={{ textAlign: 'center', alignItems: 'center' }}>
            <button type="button" variant="outlined" onClick={openModal} className="programInfoButton" style={{ marginTop: '10px' }}>
              CHANGE PROGRAM
            </button>
          </Grid>
        </Grid>
      ) : (
        <div className="col-md-6 align-self-end" style={{ marginBottom: '50px' }}>
          <button type="button" onClick={openModal} className="programInfoButton">
            CHANGE PROGRAM
          </button>
        </div>
      )} */}
      <Modal size="small" show={isOpen} onHide={closeModal} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>Message</Modal.Title>
          <IconButton aria-label="close" onClick={closeModal} style={{ marginTop: '-13px' }}>
            <CloseIcon />
          </IconButton>
        </Modal.Header>

        <Modal.Body>
          {loader ? <CommonLoader /> : null}
          <span style={{ fontSize: '16px', fontWeight: '500' }}>
            You can submit a request to change your membership program. Our Member Services team will get in touch with you at the earliest
            and guide you through the process.
          </span>
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: 'center' }}>
          <Button variant="secondary" onClick={() => setModalValue('PROCEED')} class="ButtonBG" disabled={true}>
            PROCEED
          </Button>
          <Button variant="secondary" onClick={() => setModalValue('CANCEL')} class="ButtonBG">
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="small" show={isSuccess} onHide={closeSuccessModal} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>Message</Modal.Title>
          <IconButton aria-label="close" onClick={closeSuccessModal} style={{ marginTop: '-13px' }}>
            <CloseIcon />
          </IconButton>
        </Modal.Header>

        <Modal.Body>
          {loader ? <CommonLoader /> : null}
          <b>
            {msgModal == 'PROCEED' ? (
              <span style={{ fontSize: '16px', fontWeight: '500' }}>Your request has been submitted.</span>
            ) : (
              <span style={{ fontSize: '16px', fontWeight: '500' }}>
                We could not process your request. Please contact our Member Services team at 1 (888) 366 6243 ,Monday through Friday 7:00
                am to 6:00 pm CST or email at customerservice@universalhealthfellowship.org
                {/* {localStorage.getItem('CONTACT_NUMBER')} */}
                {/* <Member Services Email>. */}
              </span>
            )}
          </b>
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: 'center' }}>
          <Button variant="secondary" onClick={closeSuccessModal} class="ButtonBG">
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
  else
  return <div style={{height:"80vh"}}></div>;
}

const EligibleServiceTabContent = ({ props }) => {
  return (
    <div className="row EligibleServiceTabContent" style={{height:window.mobileAndTabletCheck?"57vh": ""}}>
      <div className="col-12 col-md-5">
        <div className="leftData">
        {props.state.ProgramInfoData.visitFee[0] ? (
          <div className="row">
            <div className="col-12 col-md-5" style={{ paddingBottom: '16px' }}>
              {/* <h6 className="infoHeading">Eligible Services</h6> */}
              <div className="row">
                {props.state.ProgramInfoData.visitFee &&
                  props.state.ProgramInfoData.visitFee.slice(0, props.state.ProgramInfoData.visitFee.length/2).map(col => (
                    <div className="col-6 col-md-12" style={{ marginBottom: '20px' }}>
                      <p className="label">{col.programname}</p>
                      <p className="number" dangerouslySetInnerHTML={{ __html: col.amount }}></p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-12 col-md-5" style={{ paddingBottom: '16px' }}>
              {/* <h6 className="infoHeading">Eligible Services</h6> */}
              <div className="row">
                {props.state.ProgramInfoData.visitFee &&
                  props.state.ProgramInfoData.visitFee.slice(props.state.ProgramInfoData.visitFee.length/2).map(col => (
                    <div className="col-6 col-md-12" style={{ marginBottom: '20px' }}>
                      <p className="label">{col.programname}</p>
                      <p className="number" dangerouslySetInnerHTML={{ __html: col.amount }}></p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          ) : (
            <Grid
              item
              xs={12}
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <p className="label" style={{ color: "#454d58" }}>
                <b>NA</b>
              </p>
            </Grid>
          )}
        </div>
      </div>
    </div>
  )
}

const ExpenseLimitTabContent = ({ props }) => {
  return (
    <div className="row EligibleServiceTabContent" style={{height:window.mobileAndTabletCheck?"57vh": ""}}>
      <div className="col-12 col-md-12">
        <div className="leftData">
        {props.state.ProgramInfoData.sharingLimit[0] ? (
          <div className="row">
            <div className="col-12 col-md-3 sharingLimitScreen" style={{ paddingBottom: '16px' }}>
              <div className="row">
                {props.state.ProgramInfoData.sharingLimit &&
                  props.state.ProgramInfoData.sharingLimit.slice(0, 2).map(col => (
                    <div className="col-6 col-md-12" style={{ minHeight: '80px' }}>
                      <p className="label" dangerouslySetInnerHTML={{ __html: col.programname }}></p>
                      <p className="number">{col.amount}</p>
                    </div>
                  ))}
              </div>
            </div>

            <div className="col-12 col-md-3 sharingLimitScreen" style={{ paddingBottom: '16px' }}>
              <div className="row">
                {props.state.ProgramInfoData.sharingLimit &&
                  props.state.ProgramInfoData.sharingLimit.slice(2,7).map(col => (
                    <div className="col-6 col-md-12" style={{ minHeight: '80px'}}>
                      <p className="label" dangerouslySetInnerHTML={{ __html: col.programname }}></p>
                      <p className="number">{col.amount}</p>
                    </div>
                  ))}
              </div>
            </div>

            <div className="col-12 col-md-3 sharingLimitScreen" style={{ paddingBottom: '16px' }}>
              <div className="row">
                {props.state.ProgramInfoData.sharingLimit &&
                  props.state.ProgramInfoData.sharingLimit.slice(7, props.state.ProgramInfoData.sharingLimit.length).map(col => (
                    <div className="col-6 col-md-12" style={{ minHeight: '80px'}}>
                      <p className="label" dangerouslySetInnerHTML={{ __html: col.programname }}></p>
                      <p className="number">{col.amount}</p>
                    </div>
                  ))}
              </div>
            </div>

            <div className="col-12 col-md-3 sharingLimitMobileScreen" style={{ paddingBottom: '16px' }}>
              <div className="row">
                {props.state.ProgramInfoData.sharingLimit &&
                  props.state.ProgramInfoData.sharingLimit.map(col => (
                    <div className="col-6 col-md-12" style={{ marginBottom: '20px' }}>
                      <p className="label" dangerouslySetInnerHTML={{ __html: col.programname }}></p>
                      <p className="number">{col.amount}</p>
                    </div>
                  ))}
              </div>
            </div>

          </div>
          ) : (
            <Grid
              item
              xs={12}
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <p className="label" style={{ color: "#454d58" }}>
                <b>NA</b>
              </p>
            </Grid>
          )}
          
        </div>
      </div>
    </div>
  )
}

const AddOns = ({ props }) => {
  return (
    <div className="row EligibleServiceTabContent" style={{ height: window.mobileAndTabletCheck ? "57vh" : "" }}>
      <div className="col-12 col-md-12">
        <div className="leftData">
          <div className="row">
            <div className="col-12 col-md-3 sharingLimitScreen" style={{ paddingBottom: '16px' }}>
              <div className="row">
                <div className="col-6 col-md-12" style={{ minHeight: '30px' }}>
                  <h1 className="logoFont UHS6">
                    Dental
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-12" style={{ minHeight: '80px' }}>
              <div className="col-12 col-md-10" style={{ paddingLeft: '0px' }}>
                {props.state.rxSimpleShareActive ? (
                  <>
                    <span className={'Program_status_button Active'}>Active</span>
                    <div className="row" style={{ marginLeft: '0px' }}>
                      {/* <div className="col-12 col-md-12" style={{ paddingLeft: '0px' }}>
                        <p className="AddOnslabel">Add-On Monthly Fee</p>
                        <p className="AddOnsValue">
                          {
                            `$${props.state.counterDental ? props.state.counterDental * 25 : 25}`
                          }
                          <span className="AddOnslabel"> (Family Total)</span>{' '}
                        </p>
                      </div> */}
                    </div>
                    <div className="row" style={{ marginLeft: '0px' }}>
                      <div className="col-5 col-md-5" style={{ paddingLeft: '0px' }}>
                        <p className="AddOnslabel">Members Enrolled</p>
                        {props.state.addOnsObj &&
                          props.state.addOnsObj.map((val, idx) => {
                            return (val.addon.includes('NWS')) && val.status == 'AC' ? (
                              <div className="row" style={{ marginLeft: '0px' }}>
                                <p className="AddOnsValue">{`${val.prefix || ""} ${val.firstName} ${val.middleName || ""} ${val.lastName} ${val.suffix || ""}`}</p>
                              </div>
                            ) : null
                          })}
                      </div>
                      <div className="col-5 col-md-5" style={{ paddingLeft: '0px' }}>
                        <p className="AddOnslabel">Effective Date</p>
                        {props.state.addOnsObj &&
                          props.state.addOnsObj.map((val, idx) => {
                            return (val.addon.includes('NWS')) && val.status == 'AC' ? (
                              <div className="row" style={{ marginLeft: '0px' }}>
                                <p className="AddOnsValue">{val.addOnEffectiveDate && moment(val.addOnEffectiveDate).format("MMM, DD YYYY")}</p>
                              </div>
                            ) : null
                          })}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <span className={'Program_status_button DECLINED'}>Inactive</span>
                    <div className="row" style={{ marginLeft: '0px' }}>
                      {/* <div className="col-12 col-md-12" style={{ paddingLeft: '0px' }}>
                        <p className="AddOnslabel">Add-On Monthly Fee</p>
                        <p className="AddOnsValue">
                          $25 <span className="AddOnslabel">(Family Total)</span>{' '}
                        </p>
                      </div> */}
                    </div>
                  </>
                )}
                <p className='addonDescription'>

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const DiscountPrograms = ({ props }) => {
  return (
    <div className="row EligibleServiceTabContent"  style={{height:window.mobileAndTabletCheck?"57vh": ""}}>
      <div className="col-12 col-md-12">
        <div>
          <div className="row">
            <div className="col-12 col-md-5" style={{ paddingBottom: '16px' }}>
              {/* <h6 className="infoHeading">Eligible Services</h6> */}
                {props.state.ProgramInfoData.discountPrograms[0] ?
              <div>
              <div className="col-6 col-md-12" style={{ marginBottom: '20px' }}>
              <p className="label">Includes:</p>
              </div>
                {props.state.ProgramInfoData.discountPrograms &&
                  props.state.ProgramInfoData.discountPrograms ? (
                  props.state.ProgramInfoData.discountPrograms.map(col => (
                    <div className="col-12 col-md-12" style={{ marginBottom: '10px' }}>
                      <p className="number" dangerouslySetInnerHTML={{ __html: col.discountPrograms }}></p>
                    </div>
                  ))
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                  >
                    <p className="label" style={{ color: "#454d58" }}>
                      <b>NA</b>
                    </p>
                  </Grid>
                )}
              </div>
              :
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <p className="label" style={{ color: "#454d58" }}>
                    <b>NA</b>
                  </p>
                </Grid>
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
{/*const AddOnsTabContent = ({ props }) => {
const fetchData = () => {
 
    let obj = {
      clientId: props.state.clientName,
      associationId: props.state.associationId,
      brokerId: props.state.brokerId,
      fromMember: true,
      isSelectProgram: true,
      user_subId: localStorage.getItem('userMail'),
      memberId: localStorage.getItem('sourceid'),
      subID: props.state.subID
    }
    // if (props.state.subID === null || props.state.subID === undefined || props.state.subID === '') {
    //   this.setState({
    //     errorModal: true
    //   })
    // } else {
    const windowReference = window.open()
    getEncryptData(obj).then(response => {
      const URL = process.env.REACT_APP_LOGIN_ENROLLMENT + 'login#state=' + response.data.response
      windowReference.location = URL
    })
    // }
  }

  const dateformat = date => {
    const enrollmentDate = getDateInUTC(date, true)
    let nowdate = getDateInUTC(new Date(), true)

    var day = moment(enrollmentDate).format('DD')

    var mon = moment(enrollmentDate).format('MM')

    var year = moment(enrollmentDate).format('YYYY')

    var date = mon + '/' + day + '/' + year

    var todayDate = moment(nowdate).format('MM/DD/YYYY')
    let hr = moment(enrollmentDate).format('hh')

    if (date !== todayDate) {
      return moment(date).format('MMM DD, YYYY')
    } else {
      if (hr == '00') {
        return moment(enrollmentDate).format('m') + 'mins'
      }
      if (hr > 12) {
        return moment(enrollmentDate).format('hh:mm A')
      } else {
        return moment(enrollmentDate).format('hh:mm A')
      }
    }
  }

  const getDateInUTC = (date, getInMillisecs) => {
    if (date) {
      let newDateTime = new Date(date)
      return new Date(newDateTime)
    }

    return date
  }

  return (
    <div className="row addonsTab">
      {!props.netWellUser &&<div className="col-12 col-md-6 paddingRXSimpleShare">
        {/* {props.state.showwithacsm ? <h1 className="logoFont Dental">Dental</h1> : null} */}

       {/* <h1 className="logoFont Dental" style={{ marginBottom: '15px' }}>
          Dental
        </h1>

        <div className="col-12 col-md-10" style={{ paddingLeft: '0px' }}>
          {props.state.rxSimpleShareActive ? (
            <>
              <span className={'Program_status_button Active'}>Active</span>
              <div className="row" style={{ marginLeft: '0px' }}>
                <div className="col-7 col-md-7" style={{ paddingLeft: '0px' }}>
                  <p className="AddOnslabel">Members Enrolled</p>
                  {props.state.addOnsObj &&
                    props.state.addOnsObj.map((val, idx) => {
                      return val.addon == 'UHS Dental' && val.status == 'AC' ? (
                        <div className="row" style={{ marginLeft: '0px' }}>
                          <p className="AddOnsValue">{val.firstName + ' ' + val.lastName}</p>
                        </div>
                      ) : null
                    })}
                </div>

                <div className="col-5 col-md-5" style={{ paddingLeft: '0px' }}>
                  <p className="AddOnslabel">Effective Date</p>

                  {props.state.addOnsObj &&
                    props.state.addOnsObj.map((val, idx) => {
                      return val.addon == 'UHS Dental' && val.status == 'AC' ? (
                        <div className="row" style={{ marginLeft: '0px' }}>
                          <p className="AddOnsValue">{dateformat(val.addOnEffectiveDate)}</p>
                        </div>
                      ) : null
                    })}
                </div>
              </div>
              <div className="row" style={{ marginLeft: '0px' }}>
                <div className="col-12 col-md-12" style={{ paddingLeft: '0px' }}>
                  <p className="AddOnslabel">Add-On Monthly Fee</p>
                  <p className="AddOnsValue">
                    ${props.state.counterDental ? props.state.counterDental * 25 : 25}{' '}
                    <span className="AddOnslabel">(Family Total)</span>{' '}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <span className={'Program_status_button DECLINED'}>Inactive</span>
              <div className="row" style={{ marginLeft: '0px' }}>
                <div className="col-12 col-md-12" style={{ paddingLeft: '0px' }}>
                  <p className="AddOnslabel">Add-On Monthly Fee</p>
                  <p className="AddOnsValue">
                    $25 <span className="AddOnslabel">(Family Total)</span>{' '}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
          </div>} */}

      {/* -----------health tool data------------------ */}

      {/*<div className="col-12 col-md-5">
        <div className="leftDataAddons">
          <div className="row" style={{ height: '315px', overflowY: 'auto' }}>
            {!props.netWellUser && <div className="col-12 col-md-12" style={{ paddingBottom: '16px' }}>
              <h1 className="logoFont Dental" style={{ marginBottom: '15px' }}>
                Health Tools
              </h1>
              <div className="col-12 col-md-12" style={{ paddingLeft: '0px' }}>
                {props.state.healthToolAddonActive == true ? (
                  <>
                    <span className={'Program_status_button Active'}>Active</span>
                    <div className="row" style={{ marginLeft: '0px' }}>
                      <div className="col-7 col-md-6" style={{ paddingLeft: '0px' }}>
                        <p className="AddOnslabel">Members Enrolled</p>

                        {props.state.addOnsObj &&
                          props.state.addOnsObj.map((val, idx) => {
                            return val.addon == 'UHS Health Tools' && val.status == 'AC' ? (
                              <div className="row" style={{ marginLeft: '0px' }}>
                                <div className="col-12 col-md-6" style={{ paddingLeft: '0px' }}>
                                  <p className="AddOnsValue">{val.firstName + ' ' + val.lastName}</p>
                                </div>
                              </div>
                            ) : null
                          })}
                        <div className="row" style={{ marginLeft: '0px' }}>
                          <div className="col-12 col-md-12" style={{ paddingLeft: '0px' }}>
                            <p className="AddOnslabel">Add-On Monthly Fee</p>
                            <p className="AddOnsValue">
                              $25<span className="AddOnslabel">(Family Total)</span>{' '}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-5 col-md-5" style={{ paddingLeft: '0px' }}>
                        <p className="AddOnslabel">Effective Date</p>

                        {props.state.addOnsObj &&
                          props.state.addOnsObj.map((val, idx) => {
                            return val.addon == 'UHS Health Tools' && val.status == 'AC' ? (
                              <div className="row" style={{ marginLeft: '0px' }}>
                                <div className="col-12 col-md-6" style={{ paddingLeft: '0px' }}>
                                  <p className="AddOnsValue">{dateformat(val.addOnEffectiveDate)}</p>
                                </div>
                              </div>
                            ) : null
                          })}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <span className={'Program_status_button DECLINED'}>Inactive</span>
                    <div className="row" style={{ marginLeft: '0px' }}>
                      <div className="col-12 col-md-12" style={{ paddingLeft: '0px' }}>
                        <p className="AddOnslabel">Add-On Monthly Fee</p>
                        <p className="AddOnsValue">
                          $25 <span className="AddOnslabel">(Family Total)</span>{' '}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>}
          </div>
        </div>
      </div>
      { !props.netWellUser &&<div className="col-md-6">
        {window.mobileAndTabletCheck() ? (
          <Grid container className="Bottom-Blue">
            <Grid item xs={12} style={{ textAlign: 'center', alignItems: 'center' }}>
             <button
                type="button"
                onClick={fetchData}
                disabled={
                  localStorage.getItem('SOURCE') === 'Optimed' ||
                  localStorage.getItem('CLIENT_ID') == '6548' ||
                  localStorage.getItem('CLIENT_ID') == 6548 ||
                  localStorage.getItem('CLIENT_ID') == '4367' ||
                  localStorage.getItem('CLIENT_ID') == 4367 ||
                  localStorage.getItem('CLIENT_ID') == '5540' ||
                  localStorage.getItem('CLIENT_ID') == 5540 ||
                  localStorage.getItem('CLIENT_ID') == '4376' ||
                  localStorage.getItem('CLIENT_ID') == 4376 ||
                  localStorage.getItem('CLIENT_ID') == 5541 ||
                  localStorage.getItem('CLIENT_ID') == '5541' ||
                  localStorage.getItem('CLIENT_ID') == 4377 ||
                  localStorage.getItem('CLIENT_ID') == '4377'
                }
                className={
                  localStorage.getItem('SOURCE') === 'Optimed' ||
                  localStorage.getItem('CLIENT_ID') == '6548' ||
                  localStorage.getItem('CLIENT_ID') == 6548 ||
                  localStorage.getItem('CLIENT_ID') == '4367' ||
                  localStorage.getItem('CLIENT_ID') == 4367 ||
                  localStorage.getItem('CLIENT_ID') == '5540' ||
                  localStorage.getItem('CLIENT_ID') == 5540 ||
                  localStorage.getItem('CLIENT_ID') == '4376' ||
                  localStorage.getItem('CLIENT_ID') == 4376 ||
                  localStorage.getItem('CLIENT_ID') == 5541 ||
                  localStorage.getItem('CLIENT_ID') == '5541' ||
                  localStorage.getItem('CLIENT_ID') == 4377 ||
                  localStorage.getItem('CLIENT_ID') == '4377'
                    ? 'programInfoButtonDisable'
                    : 'programInfoButton'
                }
                // style={{ margin: '10px 0px 30px 0px' }}
              >
                MANAGE ADD-ONS
              </button>
              {/* <button type="button" variant="outlined" onClick={openModal} className="programInfoButton" style={{ marginTop: '10px' }}>
             CHANGE PROGRAM
           </button> */}
           {/* </Grid>
          </Grid>
        ) : (
          <button
            type="button"
            onClick={fetchData}
            disabled={
              localStorage.getItem('SOURCE') === 'Optimed' ||
              localStorage.getItem('CLIENT_ID') == '6548' ||
              localStorage.getItem('CLIENT_ID') == 6548 ||
              localStorage.getItem('CLIENT_ID') == '4367' ||
              localStorage.getItem('CLIENT_ID') == 4367 ||
              localStorage.getItem('CLIENT_ID') == '5540' ||
              localStorage.getItem('CLIENT_ID') == 5540 ||
              localStorage.getItem('CLIENT_ID') == '4376' ||
              localStorage.getItem('CLIENT_ID') == 4376 ||
              localStorage.getItem('CLIENT_ID') == 5541 ||
              localStorage.getItem('CLIENT_ID') == '5541' ||
              localStorage.getItem('CLIENT_ID') == 4377 ||
              localStorage.getItem('CLIENT_ID') == '4377'
            }
            className={
              localStorage.getItem('SOURCE') === 'Optimed' ||
              localStorage.getItem('CLIENT_ID') == '6548' ||
              localStorage.getItem('CLIENT_ID') == 6548 ||
              localStorage.getItem('CLIENT_ID') == '4367' ||
              localStorage.getItem('CLIENT_ID') == 4367 ||
              localStorage.getItem('CLIENT_ID') == '5540' ||
              localStorage.getItem('CLIENT_ID') == 5540 ||
              localStorage.getItem('CLIENT_ID') == '4376' ||
              localStorage.getItem('CLIENT_ID') == 4376 ||
              localStorage.getItem('CLIENT_ID') == 5541 ||
              localStorage.getItem('CLIENT_ID') == '5541' ||
              localStorage.getItem('CLIENT_ID') == 4377 ||
              localStorage.getItem('CLIENT_ID') == '4377'
                ? 'programInfoButtonDisable'
                : 'programInfoButton'
            }
            style={{ marginBottom: '15px ' }}
          >
            MANAGE ADD-ONS
          </button>
        )}
      </div>}
    </div>
  )
}
*/}
const EnrolledMembersTabContent = ({ props }) => {
  const [modalUpdateOpen, setUpdateModal] = React.useState(false);
  const theme = createMuiTheme({
    MuiTableCell: {
      paddingLeft: '30px',
      borderBottom: 'none'
    }
  })

  const useRowStyles = makeStyles({
    root: {
      borderBottom: '0px',
      '& > *': {
        borderBottom: 'unset',
        color: 'red'
      }
    }
  })

  const classes = useRowStyles()
  const updateHousehold = () => {
    if(localStorage.getItem("SOURCE")==="Optimed"){
      setUpdateModal(true);
    }else{
    let obj = {
      clientId: props.state.clientName,
      associationId: props.state.associationId,
      brokerId: props.state.brokerId,
      fromMember: true,
      user_subId: localStorage.getItem('userMail'),
      isHouseholdUpdate: true,
      memberId: localStorage.getItem('sourceid'),
      subID: props.state.subID,
      isEditCensus: false
    }
    let windowReference = window.open()
    getEncryptData(obj).then(response => {
      let URL = process.env.REACT_APP_LOGIN_ENROLLMENT + 'login#state=' + response.data.response

      windowReference.location = URL
    })
    }
  }

  const dateformat = date => {
    const enrollmentDate = getDateInUTC(date, true)
    let nowdate = getDateInUTC(new Date(), true)

    var day = moment.utc(enrollmentDate).format('DD')

    var mon = moment.utc(enrollmentDate).format('MM')

    var year = moment.utc(enrollmentDate).format('YYYY')

    var date = mon + '/' + day + '/' + year

    var todayDate = moment(nowdate).format('MM/DD/YYYY')
    let hr = moment(enrollmentDate).format('hh')

    if (date !== todayDate) {
      return moment(date).format('MMM DD, YYYY')
    } else {
      if (hr == '00') {
        return moment(enrollmentDate).format('m') + 'mins'
      }
      if (hr > 12) {
        return moment(enrollmentDate).format('hh:mm A')
      } else {
        return moment(enrollmentDate).format('hh:mm A')
      }
    }
  }

  const getDateInUTC = (date, getInMillisecs) => {
    if (date) {
      let newDateTime = new Date(date)
      return new Date(newDateTime)
    }

    return date
  }
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <div className={'enrolledTableClass '} style={{marginLeft:'5px'}}>
        <div className="enrolledTable">
        <Paper className={'tableContainer '}>
          <TableContainer style={{  backgroundColor: '#fff', minHeight:"32vh" }}>
            <Table className="mainTable " aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table" stickyHeader>
              <TableBody>
                <ThemeProvider theme={theme}>
                  <TableRow className={classes.root}>
                    <TableCell style={{ padding: 0 }} colSpan={12}>
                      <Box>
                        <TableRow>
                          <TableHeadCell align="left" className="innerTh" style={{ width: '250px' }}>
                            Member Name
                          </TableHeadCell>
                          <TableHeadCell align="left" className="innerTh" style={{ width: '200px' }}>
                            Member ID
                          </TableHeadCell>
                          <TableHeadCell align="left" className="innerTh" style={{ width: '200px' }}>
                            Relationship
                          </TableHeadCell>

                          <TableHeadCell align="left" className="innerTh" style={{ width: '211px' }}>
                            Birth Date
                          </TableHeadCell>

                          <TableHeadCell align="left" className="innerTh" style={{ width: '200px' }}>
                            Birth Gender
                          </TableHeadCell>

                          <TableHeadCell align="left" className="innerTh" style={{ width: '200px' }}>
                            Effective Date
                          </TableHeadCell>
                        </TableRow>

                        <TableBody>
                          {props.state.ProgramInfoData.memberDetails &&
                            props.state.ProgramInfoData.memberDetails.filter((item)=>item.status=="AC").map((val, idx) => {
                              return idx == 0 ? (
                                <TableRow className="MuiTableCellclass">
                                  <TableBodyCell style={{ fontWeight: 'bold' }}>Adult 1 {`${val.prefix || ""} ${val.firstName} ${val.middleName || ""} ${val.lastName} ${val.suffix || ""}`}</TableBodyCell>
                                  <TableBodyCell style={{ fontWeight: 'bolder', color: '#162242' }}>{val.memberId}</TableBodyCell>
                                  <TableBodyCell style={{ fontWeight: 'bolder' }}>
                                    {val.relationshipCd == '18' ? 'Self' : val.relationshipCd == '19' ? 'Child' : 'Spouse'}
                                  </TableBodyCell>
                                  <TableBodyCell style={{ fontWeight: 'bolder' }}>{dateformat(val.dateOfBirth)}</TableBodyCell>
                                  <TableBodyCell style={{ fontWeight: 'bolder' }}>{val.gender == 'F' ? 'Female' : 'Male'}</TableBodyCell>
                                  <TableBodyCell style={{ fontWeight: 'bolder' }}>
                                    {val.benefits.map(dt => {
                                      return dateformat(dt.benefitBegin)
                                    })}
                                  </TableBodyCell>
                                </TableRow>
                              ) : (
                                <TableRow className="headClass">
                                  <TableBodyCell>
                                    Adult {idx + 1} {`${val.prefix || ""} ${val.firstName} ${val.middleName || ""} ${val.lastName} ${val.suffix || ""}`}
                                  </TableBodyCell>
                                  <TableBodyCell style={{ color: '#162242' }}>{val.memberId}</TableBodyCell>
                                  <TableBodyCell>
                                    {val.relationshipCd == '18' ? 'Self' : val.relationshipCd == '19' ? 'Child' : 'Spouse'}
                                  </TableBodyCell>
                                  <TableBodyCell>{dateformat(val.dateOfBirth)}</TableBodyCell>
                                  <TableBodyCell>{val.gender == 'F' ? 'Female' : 'Male'}</TableBodyCell>
                                  <TableBodyCell>
                                    {val.benefits.map(dt => {
                                      return dateformat(dt.benefitBegin)
                                    })}
                                  </TableBodyCell>
                                </TableRow>
                              )
                            })}
                        </TableBody>
                      </Box>
                    </TableCell>
                  </TableRow>
                </ThemeProvider>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        </div>
        <div className="col-md-12" style={{ paddingLeft: '0px',paddingTop: '20px', backgroundColor: '#fff' }}>
          {window.mobileAndTabletCheck() ? (
            <Grid container className="Bottom-Blue">
              <Grid item xs={12} style={{ textAlign: 'center', alignItems: 'center' }}>
                <button
                  type="button"
                  onClick={updateHousehold}
                  disabled={localStorage.getItem("isGuardian")}
                  className={localStorage.getItem("isGuardian") ? "programInfoButtonDisable" : "programInfoButton"}>
                  UPDATE HOUSEHOLD
                </button>
              </Grid>
            </Grid>
          ) : (
            <button
              type="button"
              onClick={updateHousehold}
              disabled={localStorage.getItem("isGuardian")}
              className={localStorage.getItem("isGuardian") ? "programInfoButtonUpdateHouseholdDisable" : "programInfoButtonUpdateHousehold"}
              style={{ marginBottom: '50px ' }}
            >
              UPDATE HOUSEHOLD
            </button>
          )}
        </div>
      </div>
      <Modal size="small" show={modalUpdateOpen} onHide={() => setUpdateModal(false)} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>Message</Modal.Title>
          <IconButton aria-label="close" onClick={() => setUpdateModal(false)} style={{ marginTop: '-13px' }}>
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>  
          <b>
              <span style={{ fontSize: '16px', fontWeight: '500' }}>
              To update your household call Member Services at 855-739-6500, Monday through Friday 8.00am to 8.00pm CST.
              </span>
          </b>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <Button variant="secondary" onClick={() => setUpdateModal(false)} class="ButtonBG">
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
const datafronetWell={
  "acsm": {
      "met": 0.0,
      "remaining": 0.0,
      "total": 0.0
  },
  "programInfo": {
      "programName": "Elite+",
      "non_Shareable_Amount": "$2,500",
      "physician_and_Ancillary_Services_Network": "Multiplan/PHCS Practitioner and Ancillary Network \n(Search for Doctors/Providers  - <a href='http://findprovider.universalhealthfellowship.org/' target='_blank'>http://findprovider.universalhealthfellowship.org/</a>)"
  },
  "planInfo": [
      {
          "id": 2978,
          "planId": "7006",
          "idcardField": "Telemedicine",
          "fieldValue": "$0",
          "status": "Active",
          "type": "idcard"
      },
      {
          "id": 2979,
          "planId": "7006",
          "idcardField": "PCP Office Visit",
          "fieldValue": "$35",
          "status": "Active",
          "type": "idcard"
      },
      {
          "id": 2980,
          "planId": "7006",
          "idcardField": "Urgent Care",
          "fieldValue": "$55",
          "status": "Active",
          "type": "idcard"
      },
      {
          "id": 2981,
          "planId": "7006",
          "idcardField": "ER Visit",
          "fieldValue": "$500",
          "status": "Active",
          "type": "idcard"
      }
  ],
  "nsa": {
      "met": 0.0,
      "remaining": 2500.0,
      "total": 2500.0
  },
  "expenseLimits": [
      {
          "id": 2984,
          "planId": "7006",
          "idcardField": "Medical Incident Sharing Limit",
          "fieldValue": "$5,000",
          "status": "Active",
          "type": "expense"
      },
      {
          "id": 2985,
          "planId": "7006",
          "idcardField": "Maximum Sharing Limit Per Year",
          "fieldValue": "$750,000",
          "status": "Active",
          "type": "expense"
      },
      {
          "id": 2986,
          "planId": "7006",
          "idcardField": "Lifetime Sharing Maximum",
          "fieldValue": "$1,500,000",
          "status": "Active",
          "type": "expense"
      }
  ]
}