import Backdrop from '@material-ui/core/Backdrop'
import Fab from '@material-ui/core/Fab'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import React, { Component } from 'react'
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import { getCardEnableData, gethealthcard, getNetworkName, encryptMemberId } from '../ApiCall'
import CommonLoader from '../CommonLoader'
import IDCardMob from './Card/IDCardMob'
import MobileFooter from './MobileFooter'
import './MobileScreens.css'

const useStyles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },

  margin: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
})

const MyDoc = () => {
  return (
    <Document>
      <Page>
        <View>
          <Text>Download Test</Text>
        </View>
      </Page>
    </Document>
  )
}

class DigitalHealthCardNew extends Component {
  constructor() {
    super()
    this.state = {
      isFlipped: false,
      digitalheathcard: null,
      datanotavalabel: false,
      plainId: null,
      providerNetwork: null,
      loader: false,
      clickToDownload: false,
      network: null,
      channel: null,
      planIds: null,
      contactNumber: null,
      cardId: null,
      showEmpId: false,
      flag: false,
      visible: false,
      visiblelandsacpe: true,
      netWellUser: process.env.REACT_APP_NETWELLUSER == "true"? true :false,
    }
    this.handleClick = this.handleClick.bind(this)
    this.goBack = this.goBack.bind(this)
    this.isflippped = this.isflippped.bind(this)
    this.triggerChildAlert = this.triggerChildAlert.bind(this)
    // console.log(' Health Card MOBILE========', res.data)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }))
  }

  async componentDidMount () {
    let memberIdSource = localStorage.getItem("sourceid")
    let type = '' 

    let request = `memberid=${memberIdSource}&type=${type}&email=${localStorage.getItem('userMail')}`
    let query = await this.encryptData(request)
    let _healthCardURL = null
    _healthCardURL = this.getCommonModuleURL('HEALTHCARD', query)
    this.setState({
      commonModuleURL: _healthCardURL,
      loader: false
    })
  }

  encryptData = async request => {
    let query = await encryptMemberId(request)
    return query
  }

  getCommonModuleURL = (type, query) => {
    let baseURL = process.env.REACT_APP_IDCARD
    if(process.env.REACT_APP_NETWELLUSER == "true"){
      let token = localStorage.getItem('bearerToken')
      return baseURL + 'netwellidcard?query=' + query + '&token=' + token
    }else{
      let token = localStorage.getItem('bearerToken')
      return baseURL + 'healthcard?query=' + query + '&token=' + token
    }
  }



 
  goBack = () => {
    this.props.history.goBack()
  }

  onCardClick = () => {
    // this.setState({ loader: true })
    // this.gethealthcarddata()
    this.setState({
      // showHealthCard: true,
      visible: true
    })
  }

  handleClose = () => {
    this.setState({
      visible: false
      // isFlipped: false
    })
  }
  handleCloseLandscape = () => {
    this.setState({
      visiblelandsacpe: false
      // isFlipped: false
    })
  }
  printCardView() {
    window.print()
  }

  isflippped() {
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }))
  }

  triggerChildAlert() {
    this.refs.child.handleClick()
  }

  render() {
    const { classes } = this.props

    console.log('DIGITAL CARD DATA state is====================', this.state.digitalheathcard)
    return (
      <div class="digitalcard">
        {this.state.loader ? <CommonLoader /> : null}

        <div class={"digitalcard_headerNetWell"}>
          <ArrowBackIcon style={{ width: '24px', height: '24px', color:'#000' }} onClick={() => this.goBack()} />
          <div class={this.state.netWellUser?"digitalcard_headerdivNetWell":"digitalcard_headerdiv"}>Membership Card </div>
        </div>

        <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <iframe 
        style={{height:'100vh', width:'102vw',overflow:"hidden"}}
          src={this.state.commonModuleURL}
        ></iframe>


        </div>

        <Modal
          style={{
            overflow: 'auto',
            height: '100%'
          }}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={this.state.visible}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          // onClose={this.handleClose}
        >
          <Fade in={this.state.visible}>
            <div class="landscape-mode-for_digital_card" style={{ position: 'relative' }}>
              <IDCardMob
                providerNetwork={this.state.providerNetwork}
                data={this.state.digitalheathcard}
                cardId={this.state.cardId}
                network={this.state.network}
                contactNumber={this.state.contactNumber}
                showEmpId={this.state.showEmpId}
                isflippped={() => this.isflippped()}
                closebutton={true}
                lClose={false}
                close={true}
                mClose={true}
                handleClose={this.handleClose}
              />
            </div>
          </Fade>
        </Modal>
{/* 
        <div style={{ paddingBottom: '5vh' }}>
          <ChatIcon showAIChatIcon={true} />

          <ChatIcon openChat={() => console.log('')} />
          <MobCopyright />
        </div> */}
        <div class="fixed-bottom">
          <MobileFooter />
        </div>
      </div>
    )
  }
}

export default withStyles(useStyles)(DigitalHealthCardNew)
