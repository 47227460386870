import React from 'react'
import styleJson from '../styleJSON'
import moment from 'moment'
class CommonFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      netwell:process.env.REACT_APP_NETWELLUSER,
      style:styleJson.UHF,
      copyrighttext:""
    }
  }
  componentDidMount(){
    if(this.state.netwell==="true"||this.state.netwell===true){
      this.setState({style:styleJson.NetWell, 
        copyrighttext:`Copyright © ${moment().format("YYYY")} netWell. All rights reserved. | Powered by CarynHealth.com`
      })
    }else{
      this.setState({style:styleJson.UHF, 
        copyrighttext:"Copyright © 2022 Universal Health Fellowship. All rights reserved. | Powered by CarynHealth"
      })
    }
  }
  navigation = val => {
    if(this.state.netwell==="true"||this.state.netwell===true){
      if (val === 'linkedin') {
        window.open('https://www.linkedin.com/company/netwell')
      }
      if (val === 'facebook') {
        window.open('https://www.facebook.com/mynetwell')
      }
      if (val === 'instagram') {
        window.open('https://www.instagram.com/mynetwell/')
      }
      if (val === 'youtube') {
        window.open('https://twitter.com/MyNetWell')
      }
    }else{
    if (val === 'linkedin') {
      window.open('https://www.linkedin.com/company/universal-healthshare/')
    }
    if (val === 'facebook') {
      window.open('https://www.facebook.com/universalhealthshare')
    }
    if (val === 'instagram') {
      window.open('https://www.instagram.com/universalhealthshare/?hl=en')
    }
    if (val === 'youtube') {
      window.open('https://www.youtube.com/channel/UCRok91gnhqFQMUt9ATnjf3A')
    }
  }
  }

  render() {
    return (
      <div style={{ backgroundColor: this.state.style.backgroundColorOfFooter, bottom: 0, left: 0, right: 0, position: 'static' }}>
        <div style={{ padding: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            <p className="fontStyles footerIcons" style={{ fontSize: '11px', color: this.state.style.footerTextColor }}>
               {this.state.copyrighttext}<br />
              Version 1.1.0
            </p>
          </div>
          <div className="fontStyles footerIcons">
            <SocialIcon icon={this.state.style.linkedin} onClick={() => this.navigation('linkedin')} />
            <SocialIcon icon={this.state.style.facebook} onClick={() => this.navigation('facebook')} />
            <SocialIcon icon={this.state.style.instagram} onClick={() => this.navigation('instagram')} />
            <SocialIcon icon={this.state.style.youtube} onClick={() => this.navigation('youtube')} />
          </div>
        </div>
      </div>
    )
  }
}
export default CommonFooter

const SocialIcon = ({ icon, onClick }) => {
  return <img width={35} src={require(`../../assets/media/${icon}`)} style={{ marginRight: 10, cursor: 'pointer' }} onClick={onClick} />
}
