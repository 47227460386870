import CryptoJS from 'crypto-js'
import React, { Component } from 'react'
import { getDocuments, getsharingguidlineslink, getWelcomeBooklet } from '../ApiCall'
import CommonLoader from '../CommonLoader'
import styleJson from '../styleJSON'
export default class MobileDocuments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentmessage: '',
      loader: false,
      documentlist: null,
      netWellUser: process.env.REACT_APP_NETWELLUSER == "true"? true :false,
      styles: process.env.REACT_APP_NETWELLUSER == "true"? styleJson.NetWell :styleJson.UHF,
    }
    this.gotoDoc = this.gotoDoc.bind(this)
  }

  gotoDoc = () => {
    this.props.history.push('/DocumentsScreen')
  }

  componentDidMount(){
    getDocuments().then(response=>{
      this.setState({documentlist: response})
    })
  }
  render() {
    return (
      <div class="mob_contactinfocard">
        {this.state.loader ? <CommonLoader /> : null}

        <div class="mob_myneeds_top_container">
          <img src={require('../Images/LeftDrawer Icon/documents-icon-active.svg')} class="mob_myneeds_header_image" />
          <div class={this.state.netWellUser?"mob_myneeds_header_textNetwell":"mob_myneeds_header_text"}>Documents</div>
        </div>
{this.Document()}
      
      </div>
    )
  }
  Document=() => {
  if(this.state.documentlist && this.state.documentlist.length > 0)
  return(<div className="">
  <div className={ this.state.styles.DocumentCardBackColor +" text-left"}>
  {this.state.documentlist.map(data=>
      <div className="mob_documentscardnew_welcome_div" onClick={() => window.open(data.document)}>
          {data.description}
      </div>)}
  </div>
  <div className="container contactcard_footer"></div>
</div>)
  else
  return <div style={{ padding: '35px' }}>
  <div className="tempModalTxt">
  We are currently updating this feature and will be up shortly. Stay tuned!</div>
</div>
  }
  openAskCaryn = () => {
    let urlData = {
      memberId: localStorage.getItem('Member_EMPID'),
      query: this.state.documentmessage,
      ChannelUId: 'memberportal'
    }
    let key = CryptoJS.enc.Utf8.parse('000102030405060708090a0b0c0d0e0f')
    let iv = CryptoJS.enc.Utf8.parse('4t7w9z$C&F)J@NcR')
    let input = CryptoJS.enc.Utf8.parse(JSON.stringify(urlData))
    var dataEncrypt = CryptoJS.AES.encrypt(input, key, {
      keySize: 256 / 32,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString()
    window.open(`https://inf-labs.com/?isnav=true&data=${encodeURIComponent(dataEncrypt)}`)
  }
}
