import React, { Component } from 'react'
import Fab from '@material-ui/core/Fab'
import ForumIcon from '@material-ui/icons/Forum'
import { withStyles } from '@material-ui/styles'
import { render } from 'react-dom'
import ReimbursementLeftSide from '../ReimbursementRequest/ReimbursementLeftSide/ReimbursementLeftSide'
import ReimbursementModal1 from '../ReimbursementRequest/ReimbursementModal.js/ReimbursementModall1'
import ReimbursementModal2 from '../ReimbursementRequest/ReimbursementModal.js/ReimbursementModal2/ReimbursementModal2'
import './index.css'
import { Button } from 'react-bootstrap'
import customStyle from '../programInfo/CSS/stylesheet_UHS'

const CrudButton = withStyles(customStyle.crudBtn)(Fab)
const CrudButtonNetwell = withStyles(customStyle.crudBtnNetwell)(Fab)
const requestCheckList = [ {
    id: 1,
    title: 'providerName',
    description: ''
}, {
    id: 2,
    title: 'providerAddress',
    description: ''
}, {
    id: 3,
    title: 'providerPhoneNumber',
    description: ''
},{
    id: 4,
    title: 'providerBill',
    description: ''
},
{
    id: 5,
    title: 'providerProof',
    description: ''
}]

export default class Index extends Component{
    constructor (props){
        super(props)
this.state = {reimbursementRequest: {

    member:'',
    date: '',
    checkList: [],

},
    
 membersList: []

}}
handleDateChange = (date,didMount) => {
    console.log("date---",date)
  this.setState({
      targetDate: date,
      myDate: date
  }
  , () =>{
        let panel = document.getElementById("date-picker-dialog");
        panel.addEventListener("onmouseleave", function() {
            document.getElementById("date-picker-dialog-label").style.paddingTop = "10px";
        });
    }
    );    
}
goBack = () => {
    this.props.history.push('/')
  }
  render(){
    return(

      <> 
     
           
            
            <ReimbursementLeftSide referenceId={this.props.referenceId} memberid={this.props.memberid} backClick={this.props.backClick} className='reimbursementLeft'/>
         
             
           
         
 
     
      </>
    )
  }
}