import moment from 'moment'
import React, { Component } from 'react'

export default class MobCopyright extends Component {
  constructor(props) {
    super(props)

    this.state = {
      netWellUser: process.env.REACT_APP_NETWELLUSER == "true"? true :false,
    }
  }

  navigation = val => {
    if (val === 'linkedin') {
      window.open(this.state.netWellUser?'https://www.linkedin.com/company/netwell?original_referer=https%3A%2F%2Fwww.google.com%2F':'https://www.linkedin.com/company/universal-healthshare/')
    }
    if (val === 'facebook') {
      window.open(this.state.netWellUser?'https://www.facebook.com/mynetwell':'https://www.facebook.com/universalhealthshare')
    }
    if (val === 'instagram') {
      window.open(this.state.netWellUser?'https://www.instagram.com/mynetwell/':'https://www.instagram.com/universalhealthshare/?hl=en')
    }
    if (val === 'youtube') {
      window.open(this.state.netWellUser?'https://twitter.com/MyNetWell':'https://www.youtube.com/channel/UCRok91gnhqFQMUt9ATnjf3A')
    }
  }
  render() {
    return <div  className="fixed-bottom" style={{ backgroundColor: this.state.netWellUser?'#fffff':'#424951', bottom: '0', left: 0, right: 0, position: 'static', zIndex:100, width:'100vw', marginTop:16}}>
    <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingBottom: '37px'}}>
      <div>
        <p className="fontStyles footerIcons" style={{ fontSize: '11px', color: this.state.netWellUser?'#000000': '#e5e7ea', textAlign:'center' }}>
          Copyright © {moment().format("YYYY")} {this.state.netWellUser?'netWell':'Universal Health Fellowship'}. All rights reserved. | Powered by CarynHealth <br />
          {this.state.netWellUser?'Version 1.0':'Version 4.1.0'}
        </p>
      </div>
      <div className="fontStyles footerIcons">
        <SocialIcon icon={this.state.netWellUser?'linkedinBlack.svg':'linkedin.svg'} onClick={() => this.navigation('linkedin')} />
        <SocialIcon icon={this.state.netWellUser?'facebookBlack.svg':'facebook.png'} onClick={() => this.navigation('facebook')} />
        <SocialIcon icon={this.state.netWellUser?'instagramBlack.svg':'instagram.png'} onClick={() => this.navigation('instagram')} />
        <SocialIcon icon={this.state.netWellUser?'twitterIcon.png':'youtube.svg'} onClick={() => this.navigation('youtube')} />
      </div>
    </div>
  </div>
  }
}
const SocialIcon = ({ icon, onClick }) => {
  return <img width={35} src={require(`../../assets/media/${icon}`)} style={{ marginRight: 10, marginTop:10, cursor: 'pointer', zIndex:1029 }} onClick={onClick} />
}