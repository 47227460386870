import { Box, Grid, Hidden, Tab, Tabs } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { createMuiTheme, makeStyles, ThemeProvider, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import CloseIcon from '@material-ui/icons/Close'
import ForumIcon from '@material-ui/icons/Forum'
import axios from 'axios'
import moment from 'moment'
import React, { Component, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import {
  getAgentInfo,
  getCardEnableData,
  getClientDetails,
  getEncryptData,
  getEnrolledMemberData,
  getEnrollMemberInfoById,
  gethealthcard,
  getProgramInfo,
  getAddOnData,
  getSourceID
} from '../../ApiCall'
import CommonLoader from '../../CommonLoader'
import MobCopyright from '../../MobileScreen/MobCopyright'
import MobileFooter from '../../MobileScreen/MobileFooter'
// import '../../MobileScreen/MobileFooter.css'
import ChatIcon from '../ChatBox/ChatIcon'
import CommonFooter from '../CommonFooter'
import Header from '../Header'
import customStyle from './stylesheet_UHS'
import i18n from './i18next'
import './ProgramInformation.css'
import styleJson from '../../styleJSON';
import TextField from '@material-ui/core/TextField';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import SignScroll from "./SignScroll";
import TextSignature from "./TextSignature";
import Sample from "./sampleTextField";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CommonDropDwn from '../My Transaction/CommonDropDwn'

const useRowStyles = makeStyles({
  root: {
    borderBottom: '0px',
    borderCollapse: 'collapse',
    // padding: '15px 8px !important',
    '& > *': {
      borderBottom: 'unset',
      color: 'red'
    }
  }
})

const CrudButton = withStyles(customStyle.crudBtn)(Fab)
const CrudButtonNetwell = withStyles(customStyle.crudBtnNetwell)(Fab)
const NextButton = withStyles(customStyle.NextButtonNetwell)(Button);
const CustomeButton = withStyles(customStyle.viewNetwellBtn)(Button);
const PurpleRadio = withStyles(customStyle.radioBtn)((props) => (
  <Radio color="default" {...props} />
));
const CustomTextField = withStyles(
  customStyle.textCustomField
)(TextField);

window.mobileAndTabletCheck = function () {
  let check = false
    ; (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true
    })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

const TableHeadCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '15px 8px !important',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',

    letterSpacing: '1.12px',
    textAlign: 'left',
    color: '#454d58'
  }
})(TableCell)

const TableBodyCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '15px 8px !important',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',

    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000'
  }
})(TableCell)

const CustomTableRow = withStyles({
  root: {
    '& .MuiTableRow-root td:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },

    '& .MuiTableRow-root td:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    }
  }
})(TableRow)

const theme = createMuiTheme({
  MuiTableCell: {
    paddingLeft: '30px',
    borderBottom: 'none'
  }
})

const AntTabs = withStyles(customStyle.ProgTabs)(Tabs)
// const NextButton = withStyles(customStyle.PayButton)(Button)

class Hipaa extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loader: false,
      barRange: '0',
      barRangeYellow: '0',
      acsmTotal: '',
      acsmmet: '',
      acsmremain: '',
      nsamet: '',
      nsaremain: '',
      programname: '',
      programname2: '',
      showwithacsm: false,
      clientID: '',
      associationId: '',
      clientName: '',
      brokerId: '',
      subID: '',
      healthToolAddonActive: false,
      rxSimpleShareActive: false,
      addOnsObj: [],
      counterHealthTool: 0,
      counterRxSimpleShare: 0,
      enrolledDataMember: [],
      selectedTab: "summary",
      netWellUser: process.env.REACT_APP_NETWELLUSER == "true" ? true : false,
      styles: process.env.REACT_APP_NETWELLUSER == "true" ? styleJson.NetWell : styleJson.UHF,
      ProgramInfoData: null,
      isForm: true,
      authName: '',
      openSignScroll: false,
      clearSign: false,
      signType: "stylized",
      payflag: null,
      canvasChanged: false,
      canvasDone: false,
      imgUrl: "",
      signScrollImage: null,
      fullName: '',
      isValidFullName: true,
      submitText: null,
      tomorrow: new Date(),
      fromDate: null,
      openFrom: false,
      openTo: false,
      toDate: null,
      effectDate: null,
      openEffect: false,
      isDisableSubmit: true,
      secondRadio: null,
      thirdCheck: null,
      relation: '',
      relationList: ["PARENT", "LEGAL GUARDIAN"],
      adultList: [],
      dependentNames: [],
      dependentIds: [],
      memberEmail: '',
      loader: true,
      arr: []
    }
    this.child = React.createRef();
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  componentDidMount() {
    this.getHipaaData();
  }
   getHipaaData = ()=> {
    this.getEnrolledMemberData()

    this.setState({
      loader: true,
      isForm: true
    })
    axios.get(process.env.REACT_APP_BASE_URL_VERSION + "memberportal/getProgramInformation/" + localStorage.getItem('userMail')).then((response) => {
      this.setState({ ProgramInfoData: response.data, loader: false });
      this.getEnrollMember(localStorage.getItem("sourceid"))
    })
   }

  getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  myCallback = ({ type }) => {
    console.log('age-v ', type);
    // return age > 18 ? 'Adult' : 'Child';
  }
  groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  getEnrolledMemberData = () => {
    getEnrolledMemberData().then(res => {
      if (res && res.data) {
        console.log('rerererer ', process.env.REACT_APP_BASE_URL_VERSION + `memberportal/getHippaAuth/${res.data[0].email}`);
        axios.get(process.env.REACT_APP_BASE_URL_VERSION + `memberportal/getHippaAuth/${res.data[0].email}`)
          .then(resp => {
            console.log('memberdata - - ', resp.data.response);
            this.setState({ hipaaMemberData: resp.data.response }, () => this.mapData())
          })
        this.setState({
          enrolledDataMember: res.data,
          memberData: res.data
        })
      }
    })
  }

  mapData = () => {
    console.log('data-a-a-a', this.state.memberData, ' - - ', this.state.hipaaMemberData);
    this.state.memberData.forEach(member => {
      let age = this.getAge(member.dateOfBirth);
      member.age = age
      member.hipaaStatus = "PENDING"
      member.isValidMemberEmail = true
      if (member.guardian) member.authorizedBy = `${member.prefix || ""} ${member.firstName} ${member.middleName || ""} ${member.lastName} ${member.suffix || ""}`
      else member.authorizedBy = 'Self'

      this.state.hipaaMemberData && this.state.hipaaMemberData.forEach(hMember => {
        console.log('id-d-d-d-d', hMember, ' - - ', member.memberId);
        // const myArray = val.split(" ");
        // let firstName = myArray[0];
        if (hMember.memberId == member.memberId) {
          member.authorizedBy = hMember.authorizedBy
          member.hipaaStatus = hMember.status
          member.email = hMember.email
          member.id = hMember.id
        }
      })
    })

    this.state.memberData = this.state.memberData.filter(member => member.status != "TE" || (member.status == "TE" && member.hipaaStatus == "Completed"))

    const adultChild = this.groupBy(this.state.memberData, x => (x.age >= 18 ? 'adult' : 'child'));

    console.log(' a- - ', adultChild.get('adult'));
    console.log(' c- - ', adultChild.get('child'));
    this.setState({
      adultMembers: adultChild.get('adult'),
      childMembers: adultChild.get('child')
    }, () => {
      this.state.adultList = []
      this.state.dependentNames = []
      this.state.dependentIds = []
      this.state.adultMembers && this.state.adultMembers.forEach(adult => {
        let fullName = `${adult.prefix || ""} ${adult.firstName} ${adult.middleName || ""} ${adult.lastName} ${adult.suffix || ""}`
        if (this.state.memberData[0].guardian) adult.email = this.state.memberData[0].email
        adult.isValidMemberEmail = true
        console.log('-----00---', fullName);
        this.state.adultList = [...this.state.adultList, fullName]
      })
      this.state.childMembers && this.state.childMembers.forEach(child => {
        let fullName = `${child.prefix || ""} ${child.firstName} ${child.middleName || ""} ${child.lastName} ${child.suffix || ""}`
        if (this.state.memberData[0].guardian) child.email = this.state.memberData[0].email
        console.log('-----00---', fullName);
        this.state.dependentNames = [...this.state.dependentNames, fullName]
        this.state.dependentIds = [...this.state.dependentIds, child.memberId]
      })
      // this.state.childMembers[0].hipaaStatus = "PENDING"
      this.state.hipaaMemberData && this.state.hipaaMemberData.forEach(hMember => {
        console.log('--', hMember);
        if ((hMember.authorizedBy && !hMember.authorizedBy.includes("Self")) && hMember.memberId.includes(this.state.childMembers[0].memberId)) {
          console.log('not self', hMember);
          if(this.state.childMembers){
            this.state.childMembers[0].hipaaStatus = hMember.status
            this.state.childMembers[0].email = hMember.email
            this.state.childMembers[0].memberName = hMember.memberName
            this.state.childMembers[0].authorizedBy = hMember.authorizedBy
            this.state.childMembers[0].id = hMember.id
          }
          this.state.isValidAdultEmail = true
          this.state.adultEmail = hMember.email
          this.state.adultSelected = hMember.authorizedBy
        }
      })
      if (this.state.memberData[0].guardian) this.state.adultEmail = this.state.memberData[0].email
      console.log('child', this.state.childMembers);
      this.forceUpdate()
    })
  }

  jumpToAddons() {
    let url = window.location.href
    if (url.split('ProgramInformation?').length > 1) {
      let queryString = url.split('ProgramInformation?')[1]
      let queryParams = new URLSearchParams(queryString)
      let addons = decodeURI(queryParams.get('change_addons'))
      if (addons === 'true') {
        this.changeProgram()
      } else {
        this.updateHousehold()
      }
      this.props.history.replace('/ProgramInformation')
    }
  }
  getCardEnable = () => {
    let client_id = localStorage.getItem('CLIENT_ID')
  }

  getSourceIDbyemail() {
    getSourceID().then(res => {
      this.agentInfoget(res.data.memberIdSource)

    })
  }

  agentInfoget(sourceid) {
    if (sourceid) {
      getAgentInfo(sourceid).then(res => {
        if (res.data.response) {
          this.setState({
            agentemail: res.data.response.email,
            agentname: res.data.response.name,
            agentno: res.data.response.phone,
            brokerId: res.data.response.brokerId
          })
        } else {
        }
      })
    }
  }
  getEnrollMember = memberId => {
    getEnrollMemberInfoById(memberId).then(response => {
      if (response && response.data.response) {
        this.setState({
          subID: response.data.response.subId,
          brokerId: response.data.response.brokerId,
          loader: false
        })
      } else {
        this.setState({ loader: false })
      }
    })
  }

  changeProgram = () => {
    let obj = {
      clientId: this.state.clientName,
      associationId: this.state.associationId,
      brokerId: this.state.brokerId,
      fromMember: true,
      isSelectProgram: true,
      user_subId: localStorage.getItem('userMail'),
      memberId: localStorage.getItem('sourceid'),
      subID: this.state.subID
    }
    if (this.state.subID === null || this.state.subID === undefined || this.state.subID === '') {
      this.setState({
        errorModal: true
      })
    } else {
      let windowReference = window.open()
      getEncryptData(obj).then(response => {
        let URL = process.env.REACT_APP_LOGIN_ENROLLMENT + 'login#state=' + response.data.response
        windowReference.location = URL
      })
    }
  }

  updateHousehold = () => {
    let obj = {
      clientId: this.state.clientName,
      associationId: this.state.associationId,
      brokerId: this.state.brokerId,
      fromMember: true,
      user_subId: localStorage.getItem('userMail'),
      isHouseholdUpdate: true,
      memberId: localStorage.getItem('sourceid'),
      subID: this.state.subID,
      isEditCensus: false
    }
    if (this.state.subID === null || this.state.subID === undefined || this.state.subID === '') {
      this.setState({
        errorModal: true
      })
    } else {
      let windowReference = window.open()
      getEncryptData(obj).then(response => {
        let URL = process.env.REACT_APP_LOGIN_ENROLLMENT + 'login#state=' + response.data.response

        windowReference.location = URL
      })
    }
  }

  goBack = () => {
    this.props.history.push('/')
  }

  nameChangeHandler = (name, val, isVal, index, member) => {
    console.log('545454545 ', name, ' ', index, ' ', val, ' - ', isVal);

    if (name == 'adultEmail') {
      this.setState({
        adultEmailEntered: val,
        isValidAdultEmail: isVal,
      });
    }
    if (name == 'memberEmail') {
      member.email = val
      member.isValidMemberEmail = isVal
      this.setState({
        memberEmail: val,
        isValidMemberEmail: isVal,
      });
    } else if (name == 'fullName') {
      this.setState({
        fullName: val,
        isValidFullName: isVal,
      });
    }
    //  else {
    //   this.setState({
    //     fullName: "",
    //     isValidFullName: false,
    //   }, () => { this.enableSubmit() });
    // }
  };
  changeTextFieldHandler = (val, isValid, parentObj) => {
    if (isValid) {
      this.state[parentObj.label] = val
      const myArray = val.split(" ");
      let firstName = myArray[0];
      console.log('first ', firstName);
      this.state.adultMembers.map(adult => {
        console.log('-- ', adult);
        if (adult.firstName == firstName) {
          this.state.isAdultFilled = adult.email ? true : false
          this.state.isValidAdultEmail = adult.email ? true : false
          this.state.adultEmail = adult.email
        }
      })
    } else {
      this.state[parentObj.label] = ''
    }
    this.setState(
      {
        refresh: true
      })
  }

  sendHipaaAdult = (member) => {
    this.setState({ loader: true })
    console.log('send -- ', member);
    const date = new Date();
    const dateAsString = date.toString();
    const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
    var matches = timezone.match(/\b(\w)/g);
    var abbreviations = matches.join('');

    // this.setState({ loader: true })
    const saveData = {
      name: `${member.prefix || ""} ${member.firstName} ${member.middleName || ""} ${member.lastName} ${member.suffix || ""}`,
      memberId: member.memberId,
      authorizedBy: member.authorizedBy,
      email: member.email,
      status: "In Progress",
      timestamp: abbreviations,
      primaryEmail: this.state.memberData[0].email
    }

    if (member.hipaaStatus == "In Progress") {
      let urlData = {
        email: member.email,
        memberName: `${member.prefix || ""} ${member.firstName} ${member.middleName || ""} ${member.lastName} ${member.suffix || ""}`,
        id: member.id,
        memberId: member.memberId,
        primaryEmail: this.state.memberData[0].email,
        primaryId: this.state.memberData[0].memberIdSource,
      }
      let data = {
        email: member.email,
        memberName: `${member.prefix || ""} ${member.firstName} ${member.middleName || ""} ${member.lastName} ${member.suffix || ""}`,
        id: member.id,
        url: `${process.env.REACT_APP_MEMBER_PORTAL}HipaaAuthorization?state=${btoa(JSON.stringify(urlData))}`,
        selfAuthorization: true,
        dependentNames: "",
        primaryEmail: member.primaryEmail
      }
      console.log('data= = =  = = ', data);
      axios.post(process.env.REACT_APP_BASE_URL_VERSION + 'memberportal/sendHippaAuthentication', data)
        .then(res => {
          if (res.data.code == 200) {
            this.setState({
              isHippaSent: true,
              message: `A link to the HIPAA Authorization Form has been sent to ${member.email}.`,
              loader: false
            })
          } else {
            this.setState({
              isHippaSent: true,
              message: 'Could not send HIPAA Authorization Form.',
              loader: false
            })
          }
        })
    } else {
      axios.post(process.env.REACT_APP_BASE_URL_VERSION + 'memberportal/saveHippaAuthentication', saveData)
        .then(res => {
          console.log('save-- ', res.data);
          if (res.data.code == 200) {
            let urlData = {
              email: res.data.response.email,
              memberName: res.data.response.name,
              id: res.data.response.id,
              memberId: res.data.response.memberId,
              primaryEmail: this.state.memberData[0].email,
              primaryId: this.state.memberData[0].memberIdSource,
            }
            console.log('url ', urlData);
            let data = {
              email: res.data.response.email,
              memberName: res.data.response.name,
              id: res.data.response.id,
              url: `${process.env.REACT_APP_MEMBER_PORTAL}HipaaAuthorization?state=${btoa(JSON.stringify(urlData))}`,
              selfAuthorization: true,
              dependentNames: "",
              primaryEmail: this.state.memberData[0].email
            }
            console.log('data ', data);
            axios.post(process.env.REACT_APP_BASE_URL_VERSION + 'memberportal/sendHippaAuthentication', data)
              .then(res => {
                if (res.data.code == 200) {
                  this.setState({
                    isHippaSent: true,
                    message: `A link to the HIPAA Authorization Form has been sent to ${member.email}.`,
                    loader: false
                  })
                } else {
                  this.setState({
                    isHippaSent: true,
                    message: 'Could not send HIPAA Authorization Form.',
                    loader: false
                  })
                }
              })
          } else {
            this.setState({
              isHippaSent: true,
              message: 'Could not save HIPAA Authorization Form.',
              loader: false
            })
          }
        })
    }
  }

  sendHipaaChild = (member, adultEntered, adultEmail) => {
    this.setState({ loader: true })
    console.log('send -- ', member, ' - ');
    const date = new Date();
    const dateAsString = date.toString();
    const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
    var matches = timezone.match(/\b(\w)/g);
    var abbreviations = matches.join('');

    const myArray = this.state.adultSelected?.split(" ");
    let firstName = null
    let adultChosen = null
    if (myArray) firstName = myArray[0];

    console.log('first - - -', myArray, ' - ', firstName);
    if (member[0].guardian) {
      adultChosen = member
    } else if (firstName) {
      adultChosen = this.state.adultMembers.filter(mem => mem.firstName == firstName)
    } else {
      adultChosen = this.state.adultMembers.filter(mem => mem.email == adultEmail)
    }

    adultChosen = adultChosen[0]
    let allDeps = this.state.dependentNames.toString()
    console.log('send99 -- ', adultChosen, ' - ');

    // this.setState({ loader: true })
    const saveData = {
      name: this.state.dependentNames.toString(),
      memberId: this.state.dependentIds.toString(),
      authorizedBy: this.state.adultSelected || localStorage.getItem("guardianName"),
      email: adultEntered || adultEmail,
      status: "In Progress",
      timestamp: abbreviations,
      primaryEmail: this.state.memberData[0].email
    }
    console.log('save-- ', saveData);

    if (member[0].hipaaStatus == "In Progress") {
      let urlData = {
        email: adultEntered || adultEmail,
        memberName: this.state.adultSelected || localStorage.getItem("guardianName"),
        id: adultChosen.id || this.state.childMembers[0].id,
        memberId: adultChosen.memberId,
        primaryEmail: this.state.memberData[0].email,
        dependentNames: this.state.dependentNames.toString(),
        primaryId: this.state.memberData[0].memberIdSource,
      }
      console.log('url ', urlData);
      let data = {
        email: adultEntered || adultEmail,
        memberName: this.state.adultSelected || localStorage.getItem("guardianName"),
        id: adultChosen.id || this.state.childMembers[0].id,
        url: `${process.env.REACT_APP_MEMBER_PORTAL}HipaaAuthorization?state=${btoa(JSON.stringify(urlData))}`,
        selfAuthorization: false,
        dependentNames: this.state.dependentNames.toString(),
        primaryEmail: this.state.memberData[0].email
      }
      console.log('data ', data);
      axios.post(process.env.REACT_APP_BASE_URL_VERSION + 'memberportal/sendHippaAuthentication', data)
        .then(res => {
          if (res.data.code == 200) {
            this.setState({
              isHippaSent: true,
              message: `A link to the HIPAA Authorization Form has been sent to ${adultEntered || adultEmail}.`,
              loader: false
            })
          } else {
            this.setState({
              isHippaSent: true,
              message: 'Could not send HIPAA Authorization Form.',
              loader: false
            })
          }
        })
    } else {
      axios.post(process.env.REACT_APP_BASE_URL_VERSION + 'memberportal/saveHippaAuthentication', saveData)
        .then(res => {
          // console.log('save-- ', res.data);
          if (res.data.code == 200) {
            let urlData = {
              email: res.data.response.email,
              memberName: res.data.response.name,
              id: res.data.response.id,
              memberId: res.data.response.memberId,
              primaryEmail: this.state.memberData[0].email,
              dependentNames: this.state.dependentNames.toString(),
              primaryId: this.state.memberData[0].memberIdSource,
            }
            console.log('url ', urlData);
            let data = {
              email: res.data.response.email,
              memberName: res.data.response.name,
              id: res.data.response.id,
              url: `${process.env.REACT_APP_MEMBER_PORTAL}HipaaAuthorization?state=${btoa(JSON.stringify(urlData))}`,
              selfAuthorization: false,
              dependentNames: this.state.dependentNames.toString(),
              primaryEmail: this.state.memberData[0].email
            }
            console.log('data ', data);
            axios.post(process.env.REACT_APP_BASE_URL_VERSION + 'memberportal/sendHippaAuthentication', data)
              .then(res => {
                if (res.data.code == 200) {
                  this.setState({
                    isHippaSent: true,
                    message: `A link to the HIPAA Authorization Form has been sent to ${adultEntered || adultEmail}.`,
                    loader: false
                  })
                } else {
                  this.setState({
                    isHippaSent: true,
                    message: 'Could not send HIPAA Authorization Form.',
                    loader: false
                  })
                }
              })
          } else {
            this.setState({
              isHippaSent: true,
              message: 'Could not save HIPAA Authorization Form.',
              loader: false
            })
          }
        })
    }
  }

  handleMessageModal = () => {
    this.setState({ isHippaSent: false })
    this.getEnrolledMemberData();
    this.getHipaaData();
  }

  render() {
    console.log('render, ', (this.state.adultEmail == '' || this.state.adultEmail == null) && !this.state.isValidAdultEmail);
    return (
      <div className="progInfoMainWrapper">
        {this.state.loader ? <CommonLoader /> : null}

        <div className="mobileViewHeader">
          <div className={this.state.netWellUser ? "mobile_headerNetWell" : "mobile_header"}>
            <Link to="/">
              <ArrowBackIcon style={{ width: '24px', height: '24px', color: this.state.netWellUser ? '#000000' : '#ffffff' }} />
            </Link>
            <div className={this.state.netWellUser ? "mobile_header_titleNetWell" : "mobile_header_title"}>HIPAA Privacy Authorization</div>
          </div>
        </div>

        <div className="webHeader">
          <Header name={'HIPAA Privacy Authorization'} />
        </div>

        <div className="container progInfoContainer">
         {
          window.mobileAndTabletCheck()? " " :
          <sapn className={this.state.styles.backpage} onClick={this.goBack}>
          <ArrowBackIcon style={{ width: '24px', height: '24px', color: this.state.netWellUser ? "#4782c4" : '#162242', marginRight: '5px' }} onClick={this.goBack} />
          BACK
        </sapn>
         }

          <div className="commonWrap">

            <div className="progInfoSection" style={{ paddingBottom: '0.5px' }}>
              <h2 className="progInfoTitle" style={{ color: this.state.netWellUser ? "#162242" : '#162242', }}>HIPAA PRIVACY AUTHORIZATION</h2>
              <text style={customStyle.hipaaAuthText}>All family members are required by the Health Insurance Portability and Accountability Act, 45 C.F.R. Parts 160 and 164 to authorize netWell for use or disclosure of Protected Health Information. Enter the email address of each adult member to receive a link to the HIPAA Form to be duly filled and submitted. For all child dependents, select a parent who will act as the personal representative and submit a single combined form on their behalf.</text>
              <Box sx={{ width: '100%' }}>

                <div style={{ backgroundColor: '#fff' }}>
                  <div className={'enrolledTableClass '} style={{ marginLeft: '5px' }}>
                    <div className="enrolledTable">
                      <Paper className={'tableContainer '}>
                        <TableContainer style={{ backgroundColor: '#fff', minHeight: "32vh", marginBottom: 20 }}>
                          <Table className="mainTable " aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table" stickyHeader>
                            <TableBody>
                              <ThemeProvider theme={theme}>
                                <TableRow className={useRowStyles.root} style={{ borderCollapse: 'collapse' }}>
                                  <TableCell style={{ padding: 0, borderBottom: "none" }} colSpan={12}>
                                    <Box>
                                      <TableRow className="MuiTableCellclass">
                                        <TableHeadCell align="left" className="innerTh" style={{ width: '250px', fontWeight: 'bold' }}>
                                          NAME
                                        </TableHeadCell>
                                        <TableHeadCell align="left" className="innerTh" style={{ width: '300px', fontWeight: 'bold' }}>
                                          AUTHORIZED BY
                                        </TableHeadCell>
                                        <TableHeadCell align="left" className="innerTh" style={{ width: '300px', fontWeight: 'bold' }}>
                                          EMAIL
                                        </TableHeadCell>
                                        <TableHeadCell align="left" className="innerTh" style={{ width: '500px', fontWeight: 'bold' }}>
                                          STATUS
                                        </TableHeadCell>
                                      </TableRow>

                                      <TableBody>
                                        {/* {props.state.ProgramInfoData.memberDetails &&
                            props.state.ProgramInfoData.memberDetails.filter((item)=>item.status=="AC").map((val, idx) => {
                              return idx == 0 ? ( */}
                                        {this.state.adultMembers && this.state.adultMembers.map((member, index) => {
                                          return (
                                            <TableRow className="MuiTableCellclass">
                                              <TableBodyCell style={customStyle.tableBodyCell}>{`${member.prefix || ""} ${member.firstName} ${member.middleName || ""} ${member.lastName} ${member.suffix || ""}`}</TableBodyCell>
                                              <TableBodyCell style={customStyle.tableBodyCell}>{member.authorizedBy}</TableBodyCell>
                                              {member.guardian ?
                                              <TableBodyCell style={customStyle.tableBodyCell}>{member.email}</TableBodyCell>
                                              :
                                              member.hipaaStatus == "PENDING" && index != 0 ?
                                                <TableBodyCell style={customStyle.tableBodyCell}>
                                                  <Sample
                                                    setChild={(e, isVal) => this.nameChangeHandler("memberEmail", e, isVal, index, member)}
                                                    name={"memberEmail"}
                                                    isValidFullName={member.isValidMemberEmail}
                                                    reqFlag={true}
                                                    label={"Email Address"}
                                                    value={member.email}
                                                    disable={false}
                                                    style={customStyle.textFieldTable}
                                                    length={120}
                                                    fieldType={"email"}
                                                    errMsg={"Enter valid email"}
                                                    helperMsg={""}
                                                    parentDetails={{ name: 'memberEmail' }}
                                                  ></Sample>
                                                </TableBodyCell>
                                                :
                                                <TableBodyCell style={customStyle.tableBodyCell}>{member.email}</TableBodyCell>
                                              }
                                              <TableBodyCell style={customStyle.tableBodyCell}>
                                                <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',width:window.mobileAndTabletCheck()?'280px': '' }}>
                                                  <text style={member.hipaaStatus == "PENDING" ? customStyle.pending : member.hipaaStatus == "In Progress" ? customStyle.inProgress : customStyle.completed}>{member.hipaaStatus.toUpperCase()}</text>
                                                  <div>
                                                    {member.hipaaStatus != "Completed" &&
                                                      <button
                                                        disabled={(member.email == '' || member.email == null) || !member.isValidMemberEmail}
                                                        style={{ color: (member.email == '' || member.email == null) || !member.isValidMemberEmail ? '#e8e8e8' : '#4782c4', borderColor: (member.email == '' || member.email == null) || !member.isValidMemberEmail ? '#e8e8e8' : '#4782c4', fontSize: '15px', cursor: 'pointer',padding: '5px' }}
                                                        type="button"
                                                        onClick={() => this.sendHipaaAdult(member)}
                                                        className="programInfoButton">
                                                        SEND HIPAA FORM
                                                      </button>
                                                    }
                                                  </div>
                                                </Grid>
                                              </TableBodyCell>
                                            </TableRow>
                                          )
                                        })}
                                        {this.state.childMembers &&
                                          <TableRow style={{ borderRadius: '4px', border: 'solid 1px #c6cacc' }}>
                                            <TableBodyCell style={customStyle.tableBodyCell1}>
                                              {this.state.childMembers.map((member, index) => {
                                                return (
                                                  <div>{`${member.prefix || ""} ${member.firstName} ${member.middleName || ""} ${member.lastName} ${member.suffix || ""}`}<br /></div>
                                                )
                                              })}
                                            </TableBodyCell>
                                            <TableBodyCell  className='DropDown'   style={customStyle.tableBodyCell}>
                                              {!this.state.childMembers[0].guardian && this.state.childMembers[0].hipaaStatus == "PENDING" ?
                                                <CommonDropDwn
                                                  setChild={this.changeTextFieldHandler.bind(this)}
                                                  name={'adultSelected'}
                                                  label={'Select Adult'}
                                                  value={this.state.adultSelected}
                                                  disable={false}
                                                  style={customStyle.dropDown}
                                                  fieldType={'dropDwn'}
                                                  helperMsg={''}
                                                  List={this.state.adultList}
                                                  parentDetails={{ label: 'adultSelected' }}
                                                  required={true}
                                            
                                                ></CommonDropDwn>
                                                :
                                                <TableBodyCell style={customStyle.tableBodyCell}>{this.state.childMembers[0].authorizedBy}</TableBodyCell>
                                              }
                                            </TableBodyCell>
                                            {!this.state.childMembers[0].guardian && this.state.childMembers[0].hipaaStatus == "PENDING" ?
                                              <TableBodyCell style={customStyle.tableBodyCell}>
                                                {this.state.isAdultFilled ?
                                                  <TableBodyCell style={customStyle.tableBodyCell}>{this.state.adultEmail}</TableBodyCell>
                                                  :
                                                  <Sample
                                                    key={this.state.adultEmail}
                                                    setChild={(e, isVal) => this.nameChangeHandler("adultEmail", e, isVal, 0)}
                                                    name={"adultEmail"}
                                                    isValidFullName={this.state.isValidAdultEmail}
                                                    reqFlag={true}
                                                    label={"Email Address"}
                                                    value={this.state.adultEmail || this.state.adultEmailEntered}
                                                    disable={false}
                                                    style={customStyle.textFieldTable}
                                                    length={120}
                                                    fieldType={"email"}
                                                    errMsg={"Enter valid email"}
                                                    helperMsg={""}
                                                    parentDetails={{ name: 'adultEmail' }}
                                                  ></Sample>
                                                }
                                              </TableBodyCell>
                                              :
                                              <TableBodyCell style={customStyle.tableBodyCell}>{this.state.childMembers[0].email}</TableBodyCell>
                                            }
                                            <TableBodyCell style={customStyle.tableBodyCell}>
                                              <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',width:window.mobileAndTabletCheck()?'280px': '' }}>
                                                <text style={this.state.childMembers[0].hipaaStatus == "PENDING" ? customStyle.pending : this.state.childMembers[0].hipaaStatus == "In Progress" ? customStyle.inProgress : customStyle.completed}>{this.state.childMembers[0].hipaaStatus.toUpperCase()}</text>
                                                <div>
                                                  {this.state.childMembers[0].hipaaStatus != "Completed" &&
                                                    <button
                                                      disabled={this.state.childMembers[0].guardian ? false : (this.state.adultEmail == '' || this.state.adultEmail == null) && !this.state.isValidAdultEmail}
                                                      style={{ color: this.state.childMembers[0].guardian ? '#4782c4' : (this.state.adultEmail == '' || this.state.adultEmail == null) && !this.state.isValidAdultEmail ? '#e3e3e3' : '#4782c4', borderColor: this.state.childMembers[0].guardian ? '#4782c4' : (this.state.adultEmail == '' || this.state.adultEmail == null) && !this.state.isValidAdultEmail ? '#e3e3e3' : '#4782c4', fontSize: '15px', cursor: 'pointer',padding:'5px' }}
                                                      type="button"
                                                      onClick={() => this.sendHipaaChild(this.state.childMembers, this.state.adultEmailEntered, this.state.adultEmail)}
                                                      className="programInfoButton">
                                                      SEND HIPAA FORM
                                                    </button>}
                                                </div>
                                              </Grid>
                                            </TableBodyCell>
                                          </TableRow>
                                        }
                                      </TableBody>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </ThemeProvider>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </div>
                  </div>
                </div>
              </Box>
            </div>

            <div className="progInfoFooter" style={{ paddingTop: '0px', display: window.mobileAndTabletCheck() ? 'none' : '' }}>
              <div className="row">
                <div className="col-md-12 dpFlex">
                  <div className="footerText" style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                    <div>
                    </div>
                    <div>
                      <p>Need help?</p>
                      <p>Call our netWell Representative</p>
                      <p>on (866) NETWELL (638-9355).</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobileFooter">
            <div style={{
              bottom: '9vh', paddingBottom:
                this.state.selectedTab === "enrolledMembers" || this.state.selectedTab === "addOns" ?
                  "15vh" : "7vh"
            }}>
              <ChatIcon
                shiftup={this.state.selectedTab === "enrolledMembers" || this.state.selectedTab === "addOns" ? true : false}
                changeProgram={this.state.selectedTab === "summary" ? true : false}
                showAIChatIcon={true} />
              <div>
                <ChatIcon />
              </div>
              <div>
                <MobCopyright />
              </div>
            </div>
          </div>
          <div className="fixed-bottom" style={{ visibility: window.mobileAndTabletCheck() ? '' : 'hidden' }}>
            <MobileFooter />
          </div>
        </div>

        <div className="webFooter" style={{ margin: "40px 0px 0px" }}>
          <CommonFooter />
        </div>

        <Modal size="small" show={this.state.isHippaSent} onHide={() => this.handleMessageModal()} centered backdrop="static">
          <Modal.Header style={customStyle.modal_header}>
            <Modal.Title style={{fontWeight: 'normal'}}>Message</Modal.Title>
            <IconButton aria-label="close" onClick={() => this.handleMessageModal()} style={{ marginTop: '-13px' }}>
              <CloseIcon />
            </IconButton>
          </Modal.Header>

          <Modal.Body>
            <b>{this.state.message}</b>
          </Modal.Body>

          <Modal.Footer>
            <NextButton onClick={() => this.handleMessageModal()}>
              OK
            </NextButton>
          </Modal.Footer>
        </Modal>

        {/* ===============================Error Modal====================================== */}

      </div>
    )
  }
}
export default withRouter(Hipaa)
