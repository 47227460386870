import CloseIcon from '@material-ui/icons/Close'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import CryptoJS from 'crypto-js'
import React, { Component } from 'react'
import { Modal, Form } from 'react-bootstrap'
import { withRouter } from 'react-router'
import { getDocuments, getsharingguidlineslink, getWelcomeBooklet } from '../ApiCall'
import AskCaryn from './ChatBox/AskCaryn.png'
import styleJson from '../styleJSON'
import { Fab} from '@material-ui/core'

class DocumentsCardNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentmessage: '',
      ModalpopUp: false,
      url: '',
      enlarge: false,
      documentlist: null,
      netWellUser: process.env.REACT_APP_NETWELLUSER == "true"? true :false,
      styles: process.env.REACT_APP_NETWELLUSER == "true"? styleJson.NetWell :styleJson.UHF,
    }
  }


  componentDidMount(){
    getDocuments().then(response=>{
      this.setState({documentlist: response})
    })
  }
  render() {
    return (
      <div class="contactinfocard">
        <div class="myneeds_top_container">
          <img src={require(`../Images/LeftDrawer Icon/${this.state.styles.documentsicon}`)} class="myneeds_header_image" />
          <div class={this.state.styles.DocumentSHeader}>Documents</div>
          {this.props.close ? <CloseIcon class="modal_close_cursor" onClick={this.props.onClick} /> : null}
        </div>
               
        {this.Document()}    
       
        {/* <ChatIcon /> */}
        <Modal
          size={this.state.enlarge ? 'xl' : 'lg'}
          show={this.state.ModalpopUp}
          onHide={() => this.setState({ ModalpopUp: false, documentmessage: '' })}
          backdrop="static"
        >
          <Modal.Header className="header-container">
            <div className="logo">
              <img src={AskCaryn} />
            </div>
            <div className="caption">Your AI-Powered Assistant</div>
            <div className="icons-header">
              {this.state.enlarge ? (
                <FullscreenExitIcon style={{ color: 'white', cursor: 'pointer' }} onClick={() => this.setState({ enlarge: false })} />
              ) : (
                <FullscreenIcon style={{ color: 'white', cursor: 'pointer' }} onClick={() => this.setState({ enlarge: true })} />
              )}
              <CloseIcon
                style={{ color: 'white', marginLeft: 10, cursor: 'pointer' }}
                onClick={() => this.setState({ ModalpopUp: false, documentmessage: '' })}
              />
            </div>
          </Modal.Header>
          <iframe height={this.state.enlarge ? '550px' : '500px'} width="100%" src={this.state.url}></iframe>
        </Modal>
      </div>
    )
  }
  Document=() => {
  if(this.state.documentlist && this.state.documentlist.length > 0)
  return(<div className="">
  <div className={ this.state.styles.DocumentCardBackColor +" text-left"}>
      {this.state.documentlist.map(data=>
      <div className={this.state.styles.documentBackColor} onClick={() => window.open(data.document)}>
        {data.description}
      </div>)}
  </div>
  <div className="container contactcard_footer"></div>
</div>)
  else
  return <div style={{ padding: '35px' }}>
  <div className="tempModalTxt">
  We are currently updating this feature and will be up shortly. Stay tuned!</div>
</div>
  }
}
export default withRouter(DocumentsCardNew)
const stylesheet = {
  searchInputBox: {
    borderRadius: 40,
    paddingRight: 32,
    paddingLeft: 12,
    width: '19vw',
    borderColor: '#4782C4',
    borderWidth: 'revert'
  }
}
