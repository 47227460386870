import React, { Component } from 'react'
import styleJson from '../styleJSON'
export default class MainCard extends Component {
  constructor(props) {
    super(props)
    this.state={
      styles: process.env.REACT_APP_NETWELLUSER == "true"? styleJson.NetWell :styleJson.UHF,
    }
  }
  render() {
    return (
      <div class="Card-BG text-center">
        <img src={this.props.img} class="maincard_image_icon" />
        <p class={this.state.styles.captiontextdiv}>{this.props.name}</p>
      </div>
    )
  }
}
