import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class MobileHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      heading: props.name,
      netWellUser: process.env.REACT_APP_NETWELLUSER == "true"? true :false,
    }
  }

  render() {
    return (
      <div className={!this.state.netWellUser?"mobileHeaderWrapper":"mobileHeaderWrapperNetwell"}>
      <Link to="/">
          <ArrowBackIcon style={{ width: '24px', height: '24px', color:!this.state.netWellUser ? '#ffffff':'#000000' }} />
        </Link>
        <div className={!this.state.netWellUser?"mobileHeaderTitle":"mobileHeaderTitleNetwell"}>{this.state.heading} </div>
      </div>
    )
  }
}
